<ng-container *ngIf="!!bundle">
	<div *ngFor="let item of bundle.bundleItemDisplays; let i = index" class="row mb-3">
		<div class="col">
			<div class="card">
				<div class="card-body">
					<div class="row d-flex justify-content-end">
						<div class="col-auto">
							<button class="btn btn-danger me-1" (click)="emitItemToRemove(item)">
								Remove Item
							</button>
							<button class="btn btn-primary" (click)="emitItemToEdit(item, i)">
								Edit Item
							</button>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-12">
							<p>
								<strong>Quantity: </strong><span>{{ item.quantity | number }}</span>
							</p>
							<p>
								<strong>Daily Spend: </strong><span>{{ item.dailySpend | currency }}</span>
							</p>
							<p>
								<strong>Minimum Cost: </strong><span>{{ item.minimumCost | currency }}</span>
							</p>
							<p>
								<strong>Discount Type: </strong><span>{{ item.discountType }}</span>
							</p>
								<div *ngIf="item.discountType === BundleDiscountType.Dollar; else percentTemplate">
									<strong>Discount Amount: </strong><span>{{ item.discountAmount | currency }}</span>
								</div>
								<ng-template #percentTemplate>
									<strong>Discount Amount: </strong><span>{{ item.discountAmount }}%</span>
								</ng-template>
						</div>
						<div class="col-md-6 col-12 mt-md-0 mt-3">
							<strong>Products:</strong>
							<ul>
								<li *ngFor="let product of item.products">
									{{ product.name }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-3">
		<div class="col">
			<button class="btn btn-link text-primary" (click)="addNewItem()">
				+ Add New Item
			</button>
		</div>
	</div>
	<div class="row mt-3 d-flex justify-content-center">
		 <div class="col-auto mx-auto">
			<button class="btn btn-link text-danger" (click)="emitGoBack()">Go Back</button>
		 </div>
		 <div class="col-auto mx-auto">
			<button class="btn btn-success" (click)="emitGoToBundleContent()">Go to bundle content</button>
		 </div>
	</div>
</ng-container>
