<app-customer-header [customerId]="customerId"></app-customer-header>
<app-spinner-with-overlay [showSpinner]="showSpinner">
	<div class="row">
		<!-- left pane -->
		<div class="col-md-3">
			<ng-container *ngIf="(customerId > 0 && !!customer) && (!!siteId && siteId > 0)">
				<app-info-panel [customer]="customer" [site]="site" [distribution]="distribution"
					[hasCart]="!!shoppingCart && shoppingCart.cartData!.length > 0"
					(clearCartRequested)="proceedToNewCartCreation()"
					(cartEmailRequested)="emailCart()"></app-info-panel>
			</ng-container>
		</div>

		<div class="col-md-9">
			<ng-container *ngIf="!isSoloExtraCopiesCart">
				<!-- top buttons -->
				<div class="row mb-3">
					<div *ngIf="!!distributionId && distributionId > 0" class="col-auto">
						<button class="btn btn-primary" (click)="addWLProduct()">
							Add Marketing Product
						</button>
					</div>
					<div *ngIf="includeExtraCopiesOption && (!!distributionId && distributionId > 0)" class="col-auto">
						<button class="btn btn-primary" (click)="addExtraCopiesProduct()">
							Add Extra Copies Product
						</button>
					</div>
					<div *ngIf="hasPrintAndShipCategories" class="col-auto mx-2">
						<button class="btn btn-primary" (click)="addPrintAndShipProduct()">
							Add Print And Ship Product
						</button>
					</div>
				</div>

				<!-- bundles -->
				<ng-container *ngIf="siteBundleDisplay.length > 0 && showBundles && !(selectedBundleNew.length > 0)">
					<app-bundles [customerId]="customerId" [bundles]="siteUSelectBundles" [siteProducts]="siteProducts"
						[siteId]="siteId!" [selectedBundle]="selectedBundle"
						(bundleSelected)="handleBundleSelected($event)"
						(bundleRemoved)="handleBundleRemoved($event)"></app-bundles>
				</ng-container>
			</ng-container>

			<!-- cart -->
			<div class="row" >
				<div class="col">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title">Cart Contents</h5>

							<!-- cart contents -->
							<div *ngIf="addedProducts !== undefined && addedProducts.length > 0"
								class="table-responsive">
								<table class="table table-sm table-hover">
									<thead>
										<tr>
											<th scope="col-md-3" sortable="productName">Product Name</th>
											<th scope="col-md-3" sortable="quantity">Quantity/Budget</th>
											<th scope="col-md-3" sortable="total">Original Price</th>
											<th scope="col-md-3" sortable="total">Discount</th>
											<th scope="col-md-3" sortable="total">Total</th>
											<th scope="col-md-3" sortable="total">Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let product of addedProducts; let i=index">
											<td scope="col-md-3">
												<ngb-highlight
													result={{getProductName(product.product)}}></ngb-highlight>
											</td>
											<td scope="col-md-3">
												<div class="row">
													<div class="col">
														<ngb-highlight [result]="getQuantity(product)"></ngb-highlight>
													</div>
												</div>
											</td>
											<td scope="col-md-3">
												<div class="col">
													<ngb-highlight
														[result]="getProductPrice(product) | currency"></ngb-highlight>
												</div>
											</td>
											<td scope="col-md-3">
												<div class="col">
													-$<input name="discount" type="number" [step]="0.01"
														[max]="getProductPrice(product)" id="discount"
														style="width: 50px;" oninput="validity.valid || (value='');"
														[(ngModel)]="product.discount" (change)="clearCoupon()">
												</div>
											</td>
											<td scope="col-md-3">
												<div class="col">
													<ngb-highlight
														[result]="(getProductPrice(product) - product.discount)| currency"></ngb-highlight>
												</div>
											</td>
											<td scope="col-md-3">
												<li class="list-inline-item">
													<button class="btn btn-danger btn-sm rounded-0" type="button"
														title="Delete"
														(click)="removeCartProduct(product.product.cartProductId!)"><i
															class="fa fa-trash"></i></button>
													<button class="btn btn-info btn-sm rounded-0" type="button"
														title="Edit" (click)="editCartProduct(product)"><i
															class="fa fa-edit"></i></button>
												</li>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div *ngIf="addedBundleProducts !== undefined && addedBundleProducts.length > 0"
								class="table-responsive">
                                    <div class="row my-3">
                                        <div class="col-auto">
                                            <h5>Active Bundles:
                                                <span *ngFor="let bundle of displayBundles">
                                                    <span  class="fw-bold">{{bundle.name}}</span>
                                                    <a role="button" class="btn btn-link text-danger p-0" (click)="openModal(removeBundleModal, bundle)">Remove bundle from cart&nbsp;<i class="fa fa-times"></i></a>
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
								<table class="table table-sm table-hover">
									<thead>
										<tr>
											<th scope="col-md-3" sortable="productName">Product Name</th>
											<th scope="col-md-3" sortable="quantity">Quantity/Budget</th>
											<th scope="col-md-3" sortable="total">Original Price</th>
											<!--<th scope="col-md-3" sortable="total">Discount</th>-->
											<th scope="col-md-3" sortable="total">Bundle Discount</th>
											<th scope="col-md-3" sortable="total">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let product of addedBundleProducts; let i=index">
											<td scope="col-md-3">
												<ngb-highlight
													result={{getProductName(product.product)}}></ngb-highlight>
											</td>
											<td scope="col-md-3">
												<div class="row">
													<div class="col">
														<ngb-highlight [result]="getQuantity(product)"></ngb-highlight>
													</div>
												</div>
											</td>
											<td scope="col-md-3">
												<div class="col">
													<ngb-highlight
														[result]="getProductPrice(product) | currency"></ngb-highlight>
												</div>
											</td>
											<!--<td scope="col-md-3">
												<div class="col">
													-$<input name="discount" type="number" [step]="0.01"
														[max]="getProductPrice(product)" id="discount"
														style="width: 50px;" oninput="validity.valid || (value='');"
														[(ngModel)]="product.discount" (change)="clearCoupon()">
												</div>
											</td>-->
											<td scope="col-md-3">
												<div *ngIf="getBundleItemDiscount(product) > 0" class="col">
													-<ngb-highlight
														[result]="getBundleItemDiscount(product) | currency"></ngb-highlight>
												</div>
											</td>
											<td scope="col-md-3">
												<div class="col">
													<ngb-highlight
														[result]="getBundleItemDiscountedPrice(product) | currency"></ngb-highlight>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!-- empty cart -->
							<div *ngIf="addedProducts === undefined || addedProducts.length === 0">
								<p>The cart is empty. Add one or more products to continue.</p>
							</div>
						</div>
					</div>

					<!-- subtotal section -->
					<div class="card mt-3" *ngIf="(addedProducts!== undefined && addedProducts.length > 0) || (addedBundleProducts!== undefined && addedBundleProducts.length > 0)">
						<div class="card-body row">
							<div class="row">
								<div class="col-md-4">
									Subtotal:
								</div>
								<div class="col-md-8">
									<b>{{getTotalPrice() | currency}}</b>
								</div>
							</div>
							<div class="row mb-2"
								*ngIf="couponValidationResponse?.couponDiscount?.isValid && (couponValidationResponse?.couponDiscount?.discountAmount ?? 0 > 0)">
								<div class="col-md-4">
									Coupon ({{this.couponCode}}):
								</div>
								<div class="col-md-8">
									<b>-{{couponValidationResponse?.couponDiscount?.discountAmount | currency}}</b>
								</div>
							</div>
							<div class="row mb-2" *ngIf="getTotalDiscount() > 0">
								<div class="col-md-4">
									Total Product Discount:
								</div>
								<div class="col-md-8">
									<b>-{{getTotalDiscount() | currency}}</b>
								</div>
							</div>
                            <div class="row mb-2" *ngIf="bundleDiscount > 0">
								<div class="col-md-4">
									Total Bundle Discount:
								</div>
								<div class="col-md-8">
									<b>-{{bundleDiscount | currency}}</b>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-md-4">
									Total (not including tax):
								</div>
								<div class="col-md-8">
									<b>{{(getTotalPrice() - getTotalDiscount() - bundleDiscount -
										(couponValidationResponse?.couponDiscount?.discountAmount ?? 0)) |
										currency}}</b>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-md-4">
									<div class="form-floating">
										<input #coupon name="coupon" type="text" class="form-control" id="coupon"
											placeholder="Enter your coupon code" [ngModel]="couponCode">
										<label for="coupon">Enter your coupon code</label>
									</div>
									<p *ngIf="(couponValidationResponse && !couponValidationResponse?.couponDiscount?.isValid)"
										class="alert alert-danger">
										{{couponValidationResponse.couponDiscount!.couponMessage!}}</p>
								</div>
								<div class="col-md-8">
									<button class="btn btn-primary" type="button" [disabled]="!shoppingCart"
										(click)="applyCoupon(coupon.value)">Apply</button>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-4">
									<app-customer-addresses [submitted]="submitted" [addressType]="'Billing'"
										[customerId]="customerId" [cartAddressId]="billingAddressId"
										(addressSelected)="selectBillingAddress($event)">
									</app-customer-addresses>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-md-12">
									<button class="btn btn-lg btn-primary" (click)="proceedToCheckout()">Continue to
										Checkout</button>
								</div>
							</div>
							<div class="text-center">
								<h3>Available Coupons</h3>
								<table class="table table-hover table-panel">
									<thead>
										<tr>
											<th>Coupon Code</th>
											<th>Start Date</th>
											<th>End Date</th>
											<th>Discount</th>
											<th>Apply To</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let coupon of siteCoupons">
											<td>
												{{coupon.couponCode}}
											</td>
											<td>
												{{coupon.startDate?.toLocaleDateString()}}
											</td>
											<td>
												{{coupon.endDate?.toLocaleDateString()}}
											</td>
											<td>
												<span *ngIf="!coupon.discountPercent">{{coupon.discountAmount |
													currency}}</span>
												<span *ngIf="coupon.discountPercent">{{coupon.discountAmount}}%</span>
											</td>
											<td>
												{{coupon.applyDiscount?.toString()}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="!(selectedBundleNew.length > 0 || siteBundles.length === 0)">
				<div class="col">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title">Site Bundles</h5>
							<table class="table table-hover table-panel">
								<thead>
									<tr>
										<th>Name</th>
										<th>Products</th>
										<th>Description</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let bundle of siteBundleDisplay">
										<td>
											<strong>{{bundle.name}}</strong>
										</td>
										<td>
											<!-- <h5 class="fw-bold" *ngIf="item.productConfiguration">
												{{findUselect(item.productConfiguration[0])}}
											</h5> -->
											<div class="accordion">
												<div class="accordion-item" *ngFor="let item of bundle.bundleItemDisplays">
													<h5 class="accordion-header">
														<button *ngIf="item.productConfiguration" class="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + item.bundleItemId">
															<h5>{{findUselect(item.productConfiguration[0])}}</h5>
														</button>
													</h5>
													<div [id]="'collapse' + item.bundleItemId" class="accordion-collapse collapse">
														<div class="accordion-body">
															<p *ngIf="item.quantity > 0">
																<strong>Quantity: </strong><span>{{ item.quantity | number }}</span>
															</p>
															<p *ngIf="item.dailySpend > 0">
																<strong>Daily Spend: </strong><span>{{ item.dailySpend | currency }}</span>
															</p>
															<p *ngIf="item.minimumCost > 0">
																<strong>Minimum Cost: </strong><span>{{ item.minimumCost | currency }}</span>
															</p>
															<p>
																<strong>Discount Type: </strong><span>{{ item.discountType }}</span>
															</p>
															<div *ngIf="item.discountType === BundleDiscountType.Dollar; else percentTemplate">
																<strong>Discount Amount: </strong><span>{{ item.discountAmount | currency }}</span>
															</div>
															<ng-template #percentTemplate>
																<strong>Discount Amount: </strong><span>{{ item.discountAmount }}%</span>
															</ng-template>
															<div class="col-md-6 col-12 mt-md-0 mt-3">
																<strong>Products:</strong>
																<ul>
																	<li *ngFor="let product of item.products">
																		{{ product.name }}
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</td>
										<td>
											{{bundle.description}}
										</td>
										<td>
											<button class="btn btn-success"  (click)="addBundle(bundle)">
												Add to Cart
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</app-spinner-with-overlay>
<ng-template #removeBundleModal>
	<div [style.display]="'block'" class="modal" id="deleteBundleModal" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-lg font-primary">
			<div class="modal-content m-auto">
				<div class="modal-header">
					<h5 class="modal-title fw-bold">Remove Bundle From Cart</h5>
					<a type="button" class="close text-end" (click)="closeModals()"><span class="fas fa-times"></span></a>
				</div>
				<div class="modal-body p-5">
					<p>Are you sure you want to remove this bundle from your cart? Removing this bundle will remove all bundle cart items and any associated cart items (e.g., extra copies).</p>
				</div>
				<div class="modal-footer">
					<div class="row mt-3">
						<div class="col text-center">
							<button type="button" class="btn btn-link text-danger me-2" (click)="closeModals()">cancel</button>
							<button type="button" class="btn btn-success" (click)="removeBundleFromCart()">confirm</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
