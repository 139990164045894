import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Customer, CustomerDistribution, Site } from '@taradel/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-info-panel',
	templateUrl: './info-panel.component.html',
	styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {

	cartInfoForm: UntypedFormGroup;
	distributionId = 0;

	@Input() customer!: Customer;
	@Input() site!: Site;
	@Input() distribution?: CustomerDistribution;
	@Input() hasCart?: boolean;

	@Output() clearCartRequested = new EventEmitter<boolean>();
    @Output() cartEmailRequested = new EventEmitter();

	constructor(
        private router: Router,
		private formBuilder: UntypedFormBuilder,
		public modalService: NgbModal) {
		this.cartInfoForm = formBuilder.group({
			site: [{ value: '', disabled: true }, Validators.required],
			distribution: [{ value: '', disabled: true }, Validators.compose([])],
			customer: [{ value: '', disabled: true }, Validators.compose([])],
		});
	}

	ngOnInit(): void {
		this.c.customer.setValue(`${this.customer.customerID} - ${this.customer.firstName} ${this.customer.lastName}`);
		this.c.site.setValue(this.site.name);
		if (!!this.distribution) {
			this.distributionId = this.distribution.distributionId;
			this.c.distribution.setValue(`${this.distribution.name}`);
		}
	}

	get c() {
		return this.cartInfoForm.controls;
	}

	routeToCustomerDistributions() {
		this.router.navigate([`/customers/${this.customer.customerID}/distributions`], { queryParams: { 'siteId': this.site.siteId } });
	}

	requestClearCart() {
		this.clearCartRequested.emit(true);
	}

	emailCart() {
        this.closeModal();
		this.cartEmailRequested.emit();
	}

	closeModal() {
		this.modalService.dismissAll();
	}
}
