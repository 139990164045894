import { Component } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Drop, UsArea } from '@taradel/web-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { DirectMailProducts, PrintProductTypes } from 'services/sales-api.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-eddm-delivery-impressions',
	templateUrl: './eddm-delivery-impressions.component.html',
	styleUrls: ['./eddm-delivery-impressions.component.scss']
})
export class EddmDeliveryImpressionsComponent {
	loading: boolean = false;
	changeDistMsg: string | undefined;
	today = new Date();
	numberOfDrops = 1;
	selectedDropNum?: number;
	disableBtn = false;

	//TODO: EDDM Multiple Impressions needs to be completed


	constructor(
		public orderLayoutService: OrderlayoutService,
		public modalService: NgbModal,
		public authService: AuthenticationService,
		private toastService: ToastService
	) {
		if (this.orderLayoutService.selectedOrderItemDistributions?.length ?? 0 > 0) {
			this.numberOfDrops = this.orderLayoutService.selectedOrderItemDistributions.length;
		}
		else if (this.orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops?.length ?? 0 > 0) {
			this.numberOfDrops = this.orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops?.length!;
		}
	}

	isInPast(drop: Drop): boolean {
		const now = new Date();
		if (drop.date !== undefined && drop.date < now) {
			return true;
		}
		return false;
	}

	async setDropDate(dropNumber: number, date: NgbDate) {
		try {
			this.loading = true;
			this.orderLayoutService.selectedOrderItemDropDateMap.set(dropNumber + 1, date);
			this.orderLayoutService.selectedOrderItemAsCartProduct!.drops!.orderDrops![dropNumber].date = new Date(date.year, date.month - 1, date.day);
			await this.orderLayoutService.getDropDeliveryRange();
		}
		catch (error) {
			this.toastService.showError(`Getting Drop delivery range failed - ${error}`);
		}
		finally {
			this.loading = false;
		}
    }

	getTotal(dropNumber: number): number {
		let enableBiz = this.orderLayoutService.eddmDistributions[dropNumber].useBusiness;
		let enableBox = this.orderLayoutService.eddmDistributions[dropNumber].usePOBoxes;
		let total = 0;
		this.orderLayoutService.eddmDistributions![dropNumber].selections?.forEach(x => {
			total += x.residential;
			if (enableBiz) {
				total += x.business;
			}
			if (enableBox) {
				total += x.poBoxes;
			}
		});

		return total;
	}
	getDropTotal(dropNumber: number): number {
		if (!this.orderLayoutService.eddmDistributions[dropNumber]) {
			return 0;
		}
		const selections = this.orderLayoutService.eddmDistributions[dropNumber].selections;
		if (!selections || selections.length === 0) {
			return 0;
		}

		const dropTotals = selections.map(cur => {
			return cur.total;
		});

		return dropTotals.reduce((runningTotal: number = 0, curVal: number) => {
			return runningTotal + curVal;
		});
	}

	splitDrops(dropNumber: number) {
		if (this.orderLayoutService.eddmDistributions![dropNumber].selections) {
			this.toastService.showWarning('I should be splitting the dist into drops, but I am not!');
		}
	}

	async updateDropDate(dropNumber: number) {
		this.disableBtn = true;
		if (!this.orderLayoutService.eddmDistributions![dropNumber].selections) {
			this.toastService.showWarning('I should be splitting the dist into drops, but I am not!');
		}
		const cartProduct = this.orderLayoutService.selectedOrderItemAsCartProduct!;
		const drops = cartProduct.drops;
		if (drops) {
			if (cartProduct.uSelectId) {
				if (DirectMailProducts.findIndex(a => a === cartProduct.uSelectId) !== -1) {
					if ((drops.returnAddressId ?? 0) === 0 && (
							(drops.returnAddress1?.length ?? 0) === 0 ||
							(drops.returnCity?.length ?? 0) === 0 ||
							(drops.returnState?.length ?? 0) === 0 ||
							(drops.returnZip?.length ?? 0) === 0
						)) {
						this.toastService.showError('Please specify a return address');
						this.disableBtn = false;
						return;
					}
				}
			}
			else {
				if (PrintProductTypes.findIndex(a => a === cartProduct.type) !== -1) {
					if (
						(drops.returnAddress1?.length ?? 0) === 0 ||
						(drops.returnCity?.length ?? 0) === 0 ||
						(drops.returnState?.length ?? 0) === 0 ||
						(drops.returnZip?.length ?? 0) === 0
					) {
						this.toastService.showError('Fix your return address');
						this.disableBtn = false;
						return;
					}
				}
			}
		}

		const missingDropDates = drops?.orderDrops?.some(x => x.number === dropNumber && (x.date === undefined || isNaN(x.date.getDate())));
		if (missingDropDates) {
			this.toastService.showError('Specify drop dates for your drop(s)');
			this.disableBtn = false;
			return;
		}

		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDrops(dropNumber);
			this.toastService.showSuccess(`Drop ${dropNumber} updated successfully`);
			this.loading = false;
		}
		catch (error) {
			this.toastService.showError(`Drop ${dropNumber} updated failed`);
		}
		finally {
			this.disableBtn = false;
			this.loading = false;
		}
	}
	async updateDropDistribution(distributionId: number) {
		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDropDistribution(distributionId, this.selectedDropNum ?? 1);
			this.toastService.showSuccess('Distribution updated successfully');
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was a problem updating the order', 'Load Error');
			}
			console.log(error);
		}
		 finally {
			this.loading = false;
		}
	}

}
