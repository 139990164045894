import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, OperatorFunction, Subject, Subscription, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { BundleDiscountType, BundleDisplay, BundleItemDisplay, OrderProduct, Product, ProductsSearch } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';

export interface USelectProductIds {
	baseProductId: number,
	name: string,
	selected: boolean
};

@Component({
  selector: 'app-bundle-item-customization',
  templateUrl: './bundle-item-customization.component.html',
  styleUrls: ['./bundle-item-customization.component.scss']
})
export class BundleItemCustomizationComponent implements OnInit, OnDestroy {
	bundleItemForm: UntypedFormGroup;
	submitted = false;
	focused = false;
	selectSpecificProducts = false;
	BundleDiscountType = BundleDiscountType;
	products?: Product[];
	filteredProducts?: Product[];
	alive = true;
	disableSaveBtn = true;
	loading = false;

	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	event$ = new Subscription;

	@ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;

	@Input() bundle?: BundleDisplay;
	@Input() bundleItem?: BundleItemDisplay;
	@Input() index?: number;

	@Output() bundleItemEdited = new EventEmitter<{ index: number, updatedItem: BundleItemDisplay }>();
	@Output() goBack = new EventEmitter();

	constructor(
		formBuilder: UntypedFormBuilder,
		private productService: ProductsService
	) {
		this.bundleItemForm = formBuilder.group({
			index: new FormControl<number>(0),
			quantity: new FormControl<number>(0, Validators.min(0)),
			dailySpend: new FormControl<number>(0, Validators.min(0)),
			minimumCost: new FormControl<number>(0, Validators.min(0)),
			discountType: new FormControl<BundleDiscountType | undefined>(undefined, Validators.required),
			discountAmount: new FormControl<number>(0, Validators.min(0)),
			productConfiguration: new FormControl<number[] | undefined>(undefined),
			products: new FormControl<OrderProduct[] | undefined>(undefined, Validators.required),
			sortOrder: new FormControl<number>(0)
		});
	}

	get controls() {
		return this.bundleItemForm.controls;
	}

	async ngOnInit(): Promise<void> {
		if (!this.bundle) {
			return;
		}
		this.loading = true;
		this.products = await this.getProducts('');
		this.filteredProducts = this.products;
		this.populateForm();
		this.loading = false;
	}

	ngOnDestroy(): void {
		this.event$.unsubscribe();
	}

	async getProducts(name: string): Promise<Product[]> {
		let query = {
			name: name,
			uSelectId: 0,
			productId: 0,
			pageNo: 1,
			pageSize: 1000
		} as ProductsSearch;
		return await this.productService.getAllProducts(query);
	}

	productsFormatter = (x: { name: string }) => x.name;

	searchProducts: OperatorFunction<string, readonly Product[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
		const inputFocus$ = this.focus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			switchMap(async (term: string) => this.filteredProducts = await this.getProducts(term))
		);
	};

	getProduct(response: any) {
		const product = this.filteredProducts?.find(p => p.productId === response.item.productId);
		if (!!product) {
			this.selectProduct(product);
		}
	}

	bulkAddProducts(selectedProducts: USelectProductIds[]) {
		selectedProducts.forEach((p: USelectProductIds) => {
			const product = this.products?.find(i => i.productId === p.baseProductId);
			if (!!product) {
				this.selectProduct(product);
			}
		});
	}

	selectProduct(product: Product) {
		let productConfig = this.controls.productConfiguration.value ?? [];
		let products = this.controls.products.value ?? [];
		if (!productConfig.some((p: number) => p === product.productId)) {
			productConfig.push(product.productId);
			products.push({ productId: product.productId, name: product.name });
			this.controls.productConfiguration.setValue(productConfig);
			this.controls.products.setValue(products);
		}
	}

	removeProduct(productId: number) {
		let productConfig = this.controls.productConfiguration.value;
		let products = this.controls.products.value;
		let productConfigIndex = productConfig.findIndex((c: number) => c === productId);
		let productIndex = products.findIndex((p: { productId: number, name: string }) => p.productId === productId);
		if (productConfigIndex > -1) {
			productConfig.splice(productConfigIndex, 1);
		}
		if (productIndex > -1) {
			products.splice(productIndex, 1);
		}
		this.controls.productConfiguration.setValue(productConfig);
		this.controls.products.setValue(products);
	}

	populateForm() {
		if (!!this.bundleItem) {
			this.controls.index.setValue(this.index);
			this.controls.quantity.setValue(this.bundleItem.quantity);
			this.controls.dailySpend.setValue(this.bundleItem.dailySpend);
			this.controls.minimumCost.setValue(this.bundleItem.minimumCost);
			this.controls.discountType.setValue(this.bundleItem.discountType);
			this.controls.discountAmount.setValue(this.bundleItem.discountAmount);
			this.controls.products.setValue(this.bundleItem.products ?? []);
			this.controls.productConfiguration.setValue(this.bundleItem.productConfiguration ?? []);
			this.controls.sortOrder.setValue(this.bundleItem.sortOrder ?? 0);
		}
	}

	emitBundleItemUpdated() {
		const index = this.controls.index.value;
		const updatedItem = {
			bundleItemId: this.bundleItem ? this.bundleItem.bundleItemId : 0,
			bundleId: this.bundleItem ? this.bundleItem.bundleId : 0,
			quantity: this.controls.quantity.value,
			dailySpend: this.controls.dailySpend.value,
			minimumCost: this.controls.minimumCost.value,
			discountType: this.controls.discountType.value,
			discountAmount: this.controls.discountAmount.value,
			productConfiguration: this.controls.productConfiguration.value,
			products: this.controls.products.value,
			sortOrder: this.controls.sortOrder.value
		} as BundleItemDisplay;
		this.bundleItemEdited.emit({ index: index, updatedItem: updatedItem });
		this.bundleItemForm.reset();
	}

	emitGoBack() {
		this.goBack.emit();
	}

}
