import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CustomerDistribution } from '@taradel/web-api-client';
import { PmDataService } from 'services/pm-data.service';

@Component({
	selector: 'app-suppress-list',
	templateUrl: './suppress-list.component.html',
	styleUrls: ['./suppress-list.component.scss']
})
export class SuppressListComponent implements OnInit {
	suppressingDistOptions: CustomerDistribution[] = [];
	newDistResponse?: { suppressingDistId: number, name: string };
	suppressListForm: UntypedFormGroup;

	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	@ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;
	@Input() customerId!: number;
	@Input() originalDistId!: number;
	@Output() listSelected = new EventEmitter<{ suppressingDistId: number, name: string } | undefined>();

	constructor(private pmDataService: PmDataService,
		formBuilder: UntypedFormBuilder) {
		this.suppressListForm = formBuilder.group({
			selectedDistId: new FormControl<number>(0, Validators.required),
			newDistName: new FormControl<string>('', Validators.required)
		});
	}

	get form() {
		return this.suppressListForm.controls;
	}

	async ngOnInit(): Promise<void> {
		this.suppressingDistOptions = await this.pmDataService.getCustomerDistributionsForSuppression(this.customerId, this.originalDistId);
	}

	search: OperatorFunction<string, readonly CustomerDistribution[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
		const inputFocus$ = this.focus$;

		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			map(term =>
				term === '' ? this.suppressingDistOptions
					: this.suppressingDistOptions.filter(v => v.name!.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
			)
		);
	};

	formatter = (x: { name: string }) => x.name;

	select(result: any) {
		this.form.selectedDistId.setValue(result.item.distributionId);
		if (this.suppressListForm.valid) {
			const response = {
				suppressingDistId: this.form.selectedDistId.value,
				name: this.form.newDistName.value
			};
			this.listSelected.emit(response);
		}
		else {
			this.listSelected.emit(undefined);
		}
	}

	setNewDistName(value: string) {
		this.form.newDistName.setValue(value);
		if (this.suppressListForm.valid) {
			const response = {
				suppressingDistId: this.form.selectedDistId.value,
				name: this.form.newDistName.value
			};
			this.listSelected.emit(response);
		}
		else {
			this.listSelected.emit(undefined);
		}
	}
}
