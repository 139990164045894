import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SiteUSelectBundle, WLProduct, BundleProduct, ProductPricingResponse } from '@taradel/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingService } from 'services/pricing.service';
import { environment } from 'environment';

@Component({
  selector: 'app-bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.scss']
})
export class BundleComponent implements OnInit {
	modalRef: any;
	productPricing: ProductPricingResponse[] = [];
	currencyCode: string = environment.instance === 'US' ? 'USD' : 'CAD';
	currencySymbol: string = environment.instance === 'US' ? 'symbol' : 'symbol-narrow';


	@Input() bundle!: SiteUSelectBundle;
	@Input() siteProducts: WLProduct[] = [];
	@Input() siteId!: number;
	@Input() customerId!: number;
	@Input() disabled!: boolean;
	@Output() bundleSelected = new EventEmitter<SiteUSelectBundle>();

  constructor(private modalService: NgbModal,
	private pricingService: PricingService) { }

	ngOnInit(): void {
		if (this.bundle) {
			this.bundle.bundleProducts?.map(async prod => {
				if (!this.productPricing.some(pp => pp.baseProductId === prod.productId)) {
					const productPricing = await this.pricingService.getProductPricing(this.siteId, this.customerId, prod.productId);
					this.productPricing = this.productPricing.concat(productPricing);
				}
			});
		}
	}

	getProductName(baseProductId: number): string {
		const wlProduct = this.siteProducts.find(x => x.baseProductId === baseProductId)!;
		const productName = wlProduct ? wlProduct.fullName ?? wlProduct.name! : "";
		const nameSeparatorIndex = productName.indexOf('-');
		return (nameSeparatorIndex === -1) ? productName : productName.substring(0, nameSeparatorIndex);
	}

	getTotalImpressions(bundleProducts: BundleProduct[]): string {
		let total = 0;
		bundleProducts.map(x => total += this.getProductImpressions(x));
		if (total > 0) {
			return `${Math.round((total * 1.5) / 5000) * 5}k`;
		}
		else {
			return '';
		}
	}

	getProductImpressions(bundleProduct: BundleProduct): number {
		const total = bundleProduct.dailyBudget * bundleProduct.duration;
		const priceTier = this.productPricing?.find(x => x.baseProductId === bundleProduct.productId && (x.maximumQuantity * x.pricePerPiece) > total);
		if (priceTier) {
			return total / priceTier?.pricePerPiece!;
		}
		else {
			return 0;
		}
	}

	getImpressionRange(bundleProduct: BundleProduct): string {
		const impressions = this.getProductImpressions(bundleProduct);
		if (impressions > 0) {
			return `${Math.round((impressions * 0.5) / 5000) * 5}k - ${Math.round((impressions * 1.5) / 5000) * 5}k`;
		}
		else {
			return '';
		}
	}

	getTotal(uSelectBundle: SiteUSelectBundle): number {
		let total = 0;
		uSelectBundle.bundleProducts?.map(x => total += x.dailyBudget * x.duration);
		return uSelectBundle.coupon?.discountPercent ? total - (total * uSelectBundle.coupon?.discountAmount! / 100) : total - (uSelectBundle.coupon?.discountAmount ?? 0);
	}

	showBundleModal(modal: any) {
		this.modalRef = this.modalService.open(modal, { windowClass: "updateModalClass" });
	}

	closeModal() {
		this.modalRef.close();
	}

	selectBundle() {
		this.closeModal();
		this.bundleSelected.emit(this.bundle);
	}
}
