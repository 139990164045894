import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { uniq, flatMap } from 'lodash';
import { Topology } from 'topojson-specification';
import { Feature, FeatureCollection } from 'geojson';
import * as topojson from 'topojson';
import {
	EddmClient as WebEddmClient, PersonalizedMailClient as WebPersonalizedMailClient, SnapAdMailClient as WebSnapAdMailClient, SavedAddressedSelection, RouteIdsForLocation, UsHeatmapQuery,
	HeatmapItem, DriveTimeQuery, Geometry, GetUsGeocodeQuery, LocationGeocode, GetRouteIdsAroundPointQuery, RoutesByDriveTimeRadiusQuery, RouteIdsForPoint,
	SavedMap, CaSavedMap, GetCaGeocodeQuery, CaHeatmapQuery, GetCaRouteIdsQuery
} from '@taradel/web-api-client';
import {
	EddmClient, EddmDistribution, PersonalizedMailQuery, UsArea,
	CreateEddmDistributionQuery, PasteRoutesQuery, FileResponse, CombineMapsQuery, DistributionCentroid, RadiusResult, SnapAdMailClient,
	SnapAdMailDistribution, CreateSnapAdMailDistributionQuery, CaPasteRoutesQuery, UsSpatialResponse,
	CaSpatialResponse, CaCombineMapsQuery
} from '@taradel/admin-api-client';
import { lastValueFrom } from 'rxjs';

export interface CarrierRoute {
	carrierroute: string;
	geocoderef: string;
	city: string;
	state: string;
	zipcode: string;
	county: string;
	rescount: number;
	bizcount: number;
	boxcount: number;
	resmfdu: number;
	ressfdu: number;
}
interface PointGeometry {
	type: 'Point';
	coordinates: number[];
	properties: CarrierRoute;
}
interface TopoJson {
	type: 'Topology';
	arcs: number[][][];
	objects: { [key: string]: GeometryCollection };
}

interface PolygonGeometry {
	type: 'Polygon' | 'MultiPolygon';
	arcs: number[][];
	properties: CarrierRoute;
}
type GoogleGeometry = PointGeometry | PolygonGeometry;

interface GeometryCollection {
	type: 'GeometryCollection';
	geometries: GoogleGeometry[];
}

@Injectable({
	providedIn: 'root'
})
export class MapDataService {

	private readonly httpClient: HttpClient;
	private readonly eddmClient: EddmClient;
	private readonly snapAdmailClient: SnapAdMailClient;

	private readonly webEddmClient: WebEddmClient;
	private readonly webSnapAdmailClient: WebSnapAdMailClient;
	private readonly webPmClient: WebPersonalizedMailClient;

	constructor(http: HttpClient) {
		this.httpClient = http;
		this.eddmClient = new EddmClient(http, environment.adminApiUrl);
		this.snapAdmailClient = new SnapAdMailClient(http, environment.adminApiUrl);

		this.webEddmClient = new WebEddmClient(http, environment.webApiUrl);
		this.webSnapAdmailClient = new WebSnapAdMailClient(http, environment.webApiUrl);
		this.webPmClient = new WebPersonalizedMailClient(http, environment.webApiUrl);
	}

	getPersonalizedMailFilters(referenceId: string): Promise<PersonalizedMailQuery> {
		return lastValueFrom(this.webPmClient.getSelectedFilters(referenceId));
	}

	getSavedSelection(referenceId: string): Promise<SavedAddressedSelection> {
		return lastValueFrom(this.webPmClient.loadAddressedSelection(referenceId));
	}

	getCustomerDistribution(customerId: number, distributionId: number): Promise<EddmDistribution> {
		return lastValueFrom(this.eddmClient.getCustomerDistribution(customerId, distributionId));
	}

	getSnapAdMailDistribution(customerId: number, distributionId: number): Promise<SnapAdMailDistribution> {
		return lastValueFrom(this.snapAdmailClient.getCustomerSnapAdMailDistribution(customerId, distributionId));
	}

	loadEddmSelections(referenceId: string): Promise<SavedMap> {
		return lastValueFrom(this.webEddmClient.loadSelections(referenceId));
	}

	loadSelections(distributionId: number): Promise<UsArea[]> {
		return lastValueFrom(this.eddmClient.loadSelectionDetails(distributionId));
	}

	loadCaSelections(referenceId: string): Promise<CaSavedMap> {
		return lastValueFrom(this.webSnapAdmailClient.loadSelections(referenceId));
	}

	async geoCodeUsAddress(address: string, zipCode: string): Promise<LocationGeocode> {
		return await lastValueFrom(this.webEddmClient.getUsGeocode(new GetUsGeocodeQuery({
			address: address,
			zipCode: zipCode
		})));
	}

	getCaGeoCode(query: GetCaGeocodeQuery): Promise<LocationGeocode> {
		return lastValueFrom(this.webSnapAdmailClient.getCaGeocode(query));
	}

	getMapImage(distributionId: number): Promise<FileResponse | null> {
		return lastValueFrom(this.eddmClient.mapImage(distributionId));
	}

	getCaMapImage(distributionId: number): Promise<FileResponse | null> {
		return lastValueFrom(this.snapAdmailClient.mapImage(distributionId));
	}

	async getCaRouteIdsInArea(address: string, city: string, province: string, postalCode: string, radiusType: string, radius: number, lat: number, lng: number): Promise<RouteIdsForLocation> {
		const query = new GetCaRouteIdsQuery({
			address,
			city,
			province,
			postalCode,
			radius,
			radiusType,
			lat,
			lng
		});
		return await lastValueFrom(this.webSnapAdmailClient.getRouteIdsInArea(query));
	}

	/**
	 * This method returns all routes that touch or intersect (by any amount) the described radius or drivetime area.
	 * If you want a tight match to your tradearea, use routesByDriveTimeRadius
	 * @param latitude
	 * @param longitude
	 * @param radiusSize
	 * @param radiusType
	 * @returns
	 */
	async getRouteIdsAroundPoint(latitude: number, longitude: number, radiusSize: number, radiusType: string): Promise<RouteIdsForPoint> {
		// normalize the radius type name, in future versions of AYX, this may be case-sensitive
		let rt = radiusType.toLowerCase().indexOf('rad') >= 0 ? 'Radius': 'DriveTime';
		return await lastValueFrom(this.webEddmClient.getRouteIdsAroundPoint(new GetRouteIdsAroundPointQuery({
			latitude: latitude,
			longitude: longitude,
			radius: radiusSize,
			radiusType: rt
		})));
	}

	async getCaRouteIdsAroundPoint(latitude: number, longitude: number, radiusType: string, radius: number): Promise<string[]> {
		const query = new GetRouteIdsAroundPointQuery({
			latitude,
			longitude,
			radius,
			radiusType
		});
		const response = await lastValueFrom(this.webSnapAdmailClient.getRouteIdsAroundPoint(query));

		return response.routeIds!;
	}

	/**
	 * This method returns the routes that touch or intersect by at least 50% of their area.
	 * Provides a tighter selection than getRouteIdsAroundPoint
	 * @param latitude
	 * @param longitude
	 * @param radiusSize
	 * @param radiusType
	 * @returns
	 */
	async routesByDriveTimeRadius(latitude: number, longitude: number, radiusSize: number, radiusType: string): Promise<string[]> {
		let rt = radiusType.toLowerCase().indexOf('rad') >= 0 ? 'Radius': 'DriveTime';
		return await lastValueFrom(this.webEddmClient.routesByDriveTimeRadius(new RoutesByDriveTimeRadiusQuery({
			latitude: latitude,
			longitude: longitude,
			distance: radiusSize,
			type: rt
		})));
	}

	/**
	 * This method returns the routes that touch or intersect by at least 50% of their area.
	 * Provides a tighter selection than getRouteIdsAroundPoint
	 * @param latitude
	 * @param longitude
	 * @param radiusSize
	 * @param radiusType
	 * @returns
	 */
	async routesByDriveTimeRadiusCa(latitude: number, longitude: number, radiusSize: number, radiusType: string): Promise<string[]> {
		let rt = radiusType.toLowerCase().indexOf('rad') >= 0 ? 'Radius': 'DriveTime';
		return await lastValueFrom(this.webSnapAdmailClient.routesByDriveTimeRadius(new RoutesByDriveTimeRadiusQuery({
			latitude: latitude,
			longitude: longitude,
			distance: radiusSize,
			type: rt
		})));
	}

	async fetchFeatures(routeIds: string[]): Promise<Feature[]> {
		let zipPrefixes = routeIds.map(routeId => routeId.substr(0, 3));
		zipPrefixes = uniq(zipPrefixes);

		const fileFeatures = await Promise.all(zipPrefixes.map(async prefix => {
			const contents = <Topology>(await this.httpClient.get(
				`/spatials/eddm/${prefix}.json`,
				{ responseType: 'json' }
			).toPromise()
				.catch(() => null ));

			return contents ? <FeatureCollection>topojson.feature(contents, contents.objects.combinedFeatureCollection) : null;
		}));

		return flatMap(fileFeatures.filter(ff => !!ff), featureCollection => featureCollection!.features
			.filter(feature => routeIds.includes(feature?.properties?.geocoderef)));
	}

	async fetchCaFeatures(routeIds: string[]): Promise<Feature[]> {
		let zipPrefixes = routeIds.map(routeId => routeId.substr(0, 3));
		zipPrefixes = uniq(zipPrefixes);

		const fileFeatures = await Promise.all(zipPrefixes.map(async prefix => {
			const contents = <Topology>(await lastValueFrom(this.httpClient.get(`/spatials/snap/${prefix}.json`, { responseType: 'json' })).catch(() => null));

			return contents ? <FeatureCollection>topojson.feature(contents, contents.objects.combinedFeatureCollection) : null;
		}));


		return flatMap(fileFeatures.filter(fF => !!fF), featureCollection => featureCollection!.features
			.filter(feature => routeIds.includes(feature?.properties?.geocoderef)));
	}

	async fetchRoutes(routeIds: string[]): Promise<CarrierRoute[]> {
		let zipPrefixes = routeIds.map(routeId => routeId.substr(0, 3));
		zipPrefixes = uniq(zipPrefixes);

		const fileGeometries = await Promise.all(zipPrefixes.map(async prefix => {
			const contents = <TopoJson>(await lastValueFrom(this.httpClient.get(`/spatials/eddm/${prefix}.json`, { responseType: 'json' })));

			return contents.objects.combinedFeatureCollection.geometries;
		}));

		return flatMap(fileGeometries, geometries => geometries
			.filter(geometry => routeIds.includes(geometry.properties.geocoderef))
			.map(geometry => geometry.properties));
	}

	heatmap(query: UsHeatmapQuery): Promise<HeatmapItem[]> {
		return lastValueFrom(this.webEddmClient.heatmap(query));
	}

	caHeatmap(query: CaHeatmapQuery): Promise<HeatmapItem[]> {
		return lastValueFrom(this.webSnapAdmailClient.heatmap(query));
	}

	caSavedSpatials(distributionId: number): Promise<CaSpatialResponse[]> {
		return lastValueFrom(this.snapAdmailClient.getDistributionSavedSpatials(distributionId));
	}

	usSavedSpatials(distributionId: number): Promise<UsSpatialResponse[]> {
		return lastValueFrom(this.eddmClient.getDistributionSavedSpatials(distributionId));
	}

	driveTime(query: DriveTimeQuery): Promise<Geometry> {
		return lastValueFrom(this.webEddmClient.driveTime(query));
	}

	caDriveTime(query: DriveTimeQuery): Promise<Geometry> {
		return lastValueFrom(this.webSnapAdmailClient.driveTime(query));
	}

	uploadRoutesFromAdmin(name: string, file: File, customerId: number): Promise<number> {
		return lastValueFrom(this.eddmClient.uploadSpreadsheetFromAdmin(name, { fileName: file.name, data: file }, customerId));
	}

	saveMap(customerId: number, query: CreateEddmDistributionQuery): Promise<number> {
		return lastValueFrom(this.eddmClient.saveCustomerDistribution(customerId, query));
	}

	createSnapAdMailDistribution(customerId: number, query: CreateSnapAdMailDistributionQuery): Promise<number> {
		return lastValueFrom(this.snapAdmailClient.saveCustomerDistribution(customerId, query));
	}

	pasteRoutesFromAdmin(routesAndZips: string[], businessesChecked: boolean, poBoxesChecked: boolean, mapName: string, customerId: number): Promise<number> {
		const pasteRoutesObject = new PasteRoutesQuery({
			routesAndZips,
			businessesChecked,
			poBoxesChecked,
			mapName
		});
		return lastValueFrom(this.eddmClient.pasteRoutesFromAdmin(pasteRoutesObject, customerId));
	}

	pasteCaRoutesFromAdmin(routesAndPostalCodes: string[], housesChecked: boolean, apartmentsChecked: boolean, farmsChecked: boolean, businessesChecked: boolean, mapName: string, customerId: number): Promise<number> {
		const pasteRoutesObject = new CaPasteRoutesQuery({
			routesAndPostalCodes,
			housesChecked,
			apartmentsChecked,
			farmsChecked,
			businessesChecked,
			mapName
		});
		return lastValueFrom(this.snapAdmailClient.pasteRoutesFromAdmin(pasteRoutesObject, customerId));
	}

	combineMaps(customerId: number, distributionIds: number[], mapName: string, useBusiness: boolean, usePOBoxes: boolean): Promise<number> {
		return lastValueFrom(this.eddmClient.combineCustomerMaps(customerId, new CombineMapsQuery({
			combinedName: mapName,
			useBusiness,
			usePOBoxes,
			distributionIds
		})));
	}

	getRouteCentroid(customerId: number, distributionId: number): Promise<DistributionCentroid> {
		return lastValueFrom(this.eddmClient.getCustomerDistributionRadius(customerId, distributionId));
	}

	getCaRouteCentroid(customerId: number, distributionId: number): Promise<DistributionCentroid> {
		return lastValueFrom(this.snapAdmailClient.getCaCustomerDistributionRadius(customerId, distributionId));
	}

	getDistributionCoverageRadii(distributionId: number): Promise<RadiusResult[]> {
		return lastValueFrom(this.eddmClient.getDistributionCoverageRadii(distributionId));
	}

	getCaDistributionCoverageRadii(customerId: number, distributionId: number): Promise<RadiusResult[]> {
		return lastValueFrom(this.snapAdmailClient.getCaDistributionCoverageRadii(customerId, distributionId));
	}

	combineCaMaps(customerId: number, query: CaCombineMapsQuery): Promise<number> {
		return lastValueFrom(this.snapAdmailClient.combineCustomerMaps(customerId, query));
	}
}
