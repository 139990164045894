<div *ngIf="!selectedBundle" class="row mb-2">
	<div *ngFor="let bundle of bundles" class="col-auto">
		<app-bundle [customerId]="customerId" [bundle]="bundle" [siteProducts]="siteProducts" [siteId]="siteId" [disabled]="disabled" (bundleSelected)="handleBundleSelected($event)"></app-bundle>
	</div>
</div>
<div *ngIf="selectedBundle" class="row mb-2">
	<div class="col-auto">
		<button class="btn btn-warning" data-toggle="tooltip" data-placement="top" title="Remove package" (click)="handleSelectedBundleRemoved()">
			{{ selectedBundle.name }} Bundle Applied &nbsp;
			<span class="fas fa-times"></span>
		</button>
	</div>
</div>
