import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BundleContentResponse, BundleDiscountType, BundleDisplay, CmsContent } from '@taradel/admin-api-client';
import { BundlesService } from 'services/bundles.service';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bundle-details',
  templateUrl: './bundle-details.component.html',
  styleUrls: ['./bundle-details.component.scss']
})
export class BundleDetailsComponent implements OnInit {
	BundleDiscountType = BundleDiscountType;
	modalRef: any;

	@Input() siteId: number = 0;
	@Input() bundle?: BundleDisplay;
	@Input() contentResponse?: BundleContentResponse;
	@Input() bundleCmsContent?: CmsContent[];
	@Input() isSalesAdmin: boolean = false;
	@Input() inReviewMode: boolean = false;

	@Output() beginEditingBundle = new EventEmitter();
	@Output() saveBundle = new EventEmitter<BundleDisplay>();
	@Output() deleteBundle = new EventEmitter<BundleDisplay>();

	constructor(
		private route: ActivatedRoute,
		private bundlesService: BundlesService,
		private toastService: ToastService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.siteId = parseInt(params.get('siteId') ?? '0', 10);
		});
	}

	goToEditBundle() {
		this.beginEditingBundle.emit();
	}

	emitSaveBundle() {
		this.saveBundle.emit(this.bundle);
	}

	emitDeleteBundle() {
		this.closeModal();
		this.deleteBundle.emit(this.bundle);
	}

	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal);
	}

	closeModal() {
		this.modalService.dismissAll();
	}
}
