import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { BusinessClass, BusinessClassClient as WebBusinessClassClient, SitesClient as WebSitesClient, SiteUSelectBundle, BundlesClient as WebBundlesClient,
	USelectDesignOptionClient as WebUSelectDesignOptionClient
} from '@taradel/web-api-client';
import { Site, SitesClient, USelectAddOns,
	SiteConfig, CmsClient, SiteDetails, USelectMethod, WLProduct, Organization, OrganizationSearch,
	FileResponse, SiteConfigWithDefaults, SiteConfigCount, SiteFilterRequest,
	SiteUrl, FileParameter, USelectDesignOptionClient, USelectDesignOption, USelectDesignType, SitePowerUp,
	UpdateSiteProductsPostageRequest, USelectLanguages, WLProductLanguages, 
    UpdateSiteProductNameRequest,
    SiteBundle,
    Bundle} from '@taradel/admin-api-client';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

export class DetailedUSelectAddOns {
	siteId!: number;
	uSelectId!: number;
	uSelectName: string | undefined;
	addOnUSelectId!: number;
	addOnUSelectName: string | undefined;
	required!: boolean;
	sortOrder!: number;
}

export class DetailedUSelectDesignOption {
	siteId!: number;
	uSelectId!: number;
	uSelectName: string | undefined;
    productDesignTypeId!: USelectDesignType;
}

@Injectable({
	providedIn: 'root'
})
export class SitesService {

	private readonly sitesClient: SitesClient;
	private readonly cmsClient: CmsClient;
	private readonly uSelectDesignOptionClient: USelectDesignOptionClient;

	private readonly webSitesClient: WebSitesClient;
	private readonly webBcClient: WebBusinessClassClient;
	private readonly webUSelectDesignOptionClient: WebUSelectDesignOptionClient;
	private readonly webBundlesClient: WebBundlesClient;

	siteUSelects: USelectMethod[] = [];
	currentSiteId = -1;
	public currentSite: BehaviorSubject<Site | undefined>;

	constructor(http: HttpClient) {
		this.sitesClient = new SitesClient(http, environment.adminApiUrl);
		this.cmsClient = new CmsClient(http, environment.adminApiUrl);
		this.uSelectDesignOptionClient = new USelectDesignOptionClient(http, environment.adminApiUrl);

		this.webSitesClient = new WebSitesClient(http, environment.webApiUrl);
        this.webBcClient = new WebBusinessClassClient(http, environment.webApiUrl);
		this.webUSelectDesignOptionClient = new WebUSelectDesignOptionClient(http, environment.webApiUrl);
		this.webBundlesClient = new WebBundlesClient(http, environment.webApiUrl);

		this.currentSite = new BehaviorSubject<Site | undefined>(undefined);
	}

	//Service Info
	async setCurrentSiteId(siteId: number) {
		this.siteUSelects = await this.getAllUSelect();
		if (siteId !== this.currentSiteId) {
			this.currentSiteId = siteId;
			this.currentSite.next(await this.getSite(siteId));
		}
	}

	//Site Info
	getAllSites(): Promise<Site[]> {
		return lastValueFrom(this.sitesClient.getAllSites());
	}

	getSite(siteId: number): Promise<Site> {
		return lastValueFrom(this.webSitesClient.getSite(siteId));
	}

	updateSite(site: Site): Promise<FileResponse | null> {
		return lastValueFrom(this.sitesClient.updateSite(site));
	}

	createSite(site: Site): Promise<number> {
	//Change when Web API is updated
		return lastValueFrom(this.sitesClient.createSite(site));
	}

	getAllBusinessClass(): Promise<BusinessClass[]> {
		return lastValueFrom(this.webBcClient.getAllBusinessClasses());
	}

	//CMS Settings
	getSettingsForOneSite(siteId: number): Promise<SiteConfig[]> {
		return lastValueFrom(this.sitesClient.getSiteConfig(siteId));
	}

	/** **NOTE:** This should **ONLY** be used on the site settings pages.  Use SiteConfigService to get the settings for a site. */
	getSettingsWithDefaults(siteId: number): Promise<SiteConfigWithDefaults[]> {
		return lastValueFrom(this.sitesClient.getSiteConfigWithDefaults(siteId));
	}

	getSettingCounts(): Promise<SiteConfigCount[]> {
		return lastValueFrom(this.sitesClient.getSiteConfigCounts());
	}
	getSettingForAllSites(configCat: string, configName: string): Promise<SiteConfig[]> {
		return lastValueFrom(this.sitesClient.getSettingForAllSites(configCat, configName));
	}

	updateCMSSetting(config: SiteConfig): Promise<number> {
		return lastValueFrom(this.cmsClient.upsertSiteConfig(config));
	}

	createCMSSetting(config: SiteConfig): Promise<number> {
		return lastValueFrom(this.cmsClient.newSiteConfig(config));
	}

	deleteCMSSetting(siteId: number, configCat: string, configName: string): Promise<string> {
		return lastValueFrom(this.cmsClient.deleteSiteConfig(siteId, configCat, configName));
	}

	//Site Details
	getSiteDetails(siteId: number): Promise<SiteDetails> {
		return lastValueFrom(this.cmsClient.getSiteDetails(siteId));
	}

	updateSiteDetails(detail: SiteDetails): Promise<boolean> {
		return lastValueFrom(this.cmsClient.updateSiteDetails(detail));
	}

	createSiteDetails(siteId: number, detail: SiteDetails): Promise<number> {
		return lastValueFrom(this.cmsClient.createSiteDetails(siteId, detail));
	}

	//Delete endpoint will delete all settings, do not use.

	// USelect
	getAllUSelect(): Promise<USelectMethod[]> {
		return lastValueFrom(this.webSitesClient.getAllUSelectMethods());
	}

	getUSelect(uSelectId: number): Promise<USelectMethod> {
		return lastValueFrom(this.webSitesClient.getUSelectMethod(uSelectId));
	}

	addUSelect(uSelectMethod: USelectMethod): Promise<number> {
		return lastValueFrom(this.sitesClient.addUSelectMethod(uSelectMethod));
	}

	updateUSelect(uSelectMethod: USelectMethod): Promise<number> {
		return lastValueFrom(this.sitesClient.updateUSelectMethod(uSelectMethod));
	}

	//Site
	getSiteProducts(siteId: number): Promise<WLProduct[]> {
		return lastValueFrom(this.webSitesClient.getAllSiteProducts(siteId));
	}

	getWLProduct(siteId: number, baseProductId: number): Promise<WLProduct> {
		return lastValueFrom(this.webSitesClient.getWLProduct(siteId, baseProductId));
	}

	addSiteProduct(wlProduct: WLProduct): Promise<number> {
		return lastValueFrom(this.sitesClient.createWLProduct(wlProduct));
	}

	deleteSiteProduct(siteId: number, baseProductId: number): Promise<number> {
		return lastValueFrom(this.sitesClient.deleteWLProduct(siteId, baseProductId));
	}

	updateSiteProductName(request: UpdateSiteProductNameRequest): Promise<number> {
		return lastValueFrom(this.sitesClient.updateWLProductName(request));
	}

	updateSiteProduct(wlProduct: WLProduct): Promise<number> {
		return lastValueFrom(this.sitesClient.updateWLProduct(wlProduct));
	}

	getSiteProductsByUselect(siteId: number, uSelectId: number): Promise<WLProduct[]> {
		return lastValueFrom(this.webSitesClient.getSiteWLProductsByUselect(siteId, uSelectId));
	}

	addSiteUrl(siteUrl: SiteUrl): Promise<number> {
		return lastValueFrom(this.sitesClient.addSiteUrl(siteUrl));
	}

	async updateSiteUrl(siteUrl: SiteUrl): Promise<void> {
		await lastValueFrom(this.sitesClient.updateSiteUrl(siteUrl));
	}

	async deleteSiteUrl(siteUrlId: number): Promise<void> {
		await lastValueFrom(this.sitesClient.deleteSiteUrl(siteUrlId));
	}

	/** all of the uselect methods **/
	async setListOfSiteUSelectAddOns(addOnsList: USelectAddOns[]): Promise<DetailedUSelectAddOns[]> {
		return await Promise.all(addOnsList.map(uSelectAddOn => this.getUSelectAddOnDetails(uSelectAddOn)));
	}

	async getSiteUSelectAddOns(siteId: number): Promise<USelectAddOns[]> {
		return await lastValueFrom(this.webSitesClient.getSiteUSelectAddOns(siteId));
	}

	getUSelectAddOnDetails(addOn: USelectAddOns) {
		let detailedUSelectAddOn = new DetailedUSelectAddOns();
		detailedUSelectAddOn.siteId = addOn.siteId;
		detailedUSelectAddOn.uSelectId = addOn.uSelectId;
		detailedUSelectAddOn.uSelectName = this.getUSelectName(addOn.uSelectId);
		detailedUSelectAddOn.addOnUSelectId = addOn.addOnUSelectId;
		detailedUSelectAddOn.addOnUSelectName = this.getUSelectName(addOn.addOnUSelectId);
		detailedUSelectAddOn.required = addOn.required;
		detailedUSelectAddOn.sortOrder = addOn.sortOrder;
		return detailedUSelectAddOn;
	}

	async getSitePowerUps(siteId: number): Promise<SitePowerUp[]> {
		return await lastValueFrom(this.webSitesClient.getSitePowerUps(siteId));
	}

	async updateSitePowerUps(siteId: number, sitePowerUps: SitePowerUp[]): Promise<number> {
		return await lastValueFrom(this.sitesClient.updateSitePowerUps(siteId, sitePowerUps));
	}


	getUSelectName(id: number) {
		return this.siteUSelects.find( uSelect => uSelect.uSelectId === id)?.name || '';
	}

	async getSiteUSelectAddOnsByUSelectId(siteId: number, uSelectId: number): Promise<USelectAddOns[]> {
		return await lastValueFrom(this.webSitesClient.getSiteUSelectAddOnsByUSelectId(siteId, uSelectId));
	}

	async updateSiteUSelectAddOnsByUSelectId(uSelectAddons: USelectAddOns[]): Promise<number> {
		return await lastValueFrom(this.sitesClient.updateSiteUSelectAddonsByUSelectId(uSelectAddons));
	}

	async addSiteUSelect(uSelectAddOns: USelectAddOns): Promise<number> {
		return await lastValueFrom(this.sitesClient.addSiteUSelect(uSelectAddOns));
	}

	async removeSiteUSelect(siteId: number, uSelectId: number): Promise<number> {
		return await lastValueFrom(this.sitesClient.removeSiteUSelect(siteId, uSelectId));
	}

	async exportSiteConfigs(siteId: number): Promise<FileResponse | null> {
		return await lastValueFrom(this.sitesClient.exportSiteSettings(siteId));
	}

	async importSiteConfigs(siteId: number, file: FileParameter): Promise<void> {
		await lastValueFrom(this.sitesClient.importSiteSettings(siteId, file));
	}

	async getSitesForAdmin(request: SiteFilterRequest): Promise<Site[]> {
		return await lastValueFrom(this.sitesClient.getSitesForAdmin(request));
	}

	async getSitesForOrganization(organizationId: number): Promise<Site[]> {
		return await lastValueFrom(this.sitesClient.getSitesForOrganization(organizationId));
	}

	async getSiteDesignOptions(siteId: number): Promise<USelectDesignOption[]> {
		return await lastValueFrom(this.webUSelectDesignOptionClient.getSiteDesignOptions(siteId));
	}

	async getSiteUSelectDesignOptions(siteId: number, uSelectId: number): Promise<USelectDesignOption[]> {
		return await lastValueFrom(this.webUSelectDesignOptionClient.getSiteUSelectDesignOptions(siteId, uSelectId));
	}

	async updateSiteUSelectDesigns(siteId: number, uSelectId: number, uSelectDesignOptions: USelectDesignOption[]): Promise<number> {
		return await lastValueFrom(this.uSelectDesignOptionClient.updateSiteUSelectDesignOptions(siteId, uSelectId, uSelectDesignOptions));
	}

	async addSiteUSelectDesignOption(uSelectDesignOption: USelectDesignOption): Promise<number> {
		return await lastValueFrom(this.uSelectDesignOptionClient.addSiteUSelectDesignOption(uSelectDesignOption));
	}

	async removeSiteUSelectDesign(siteId: number, uSelectId: number): Promise<number> {
		return await lastValueFrom(this.uSelectDesignOptionClient.removeSiteUSelectDesignOptions(siteId, uSelectId));
	}

	async setListOfSiteUSelectDesignOptions(uSelectDesignOptions: USelectDesignOption[]): Promise<DetailedUSelectDesignOption[]> {
		return await Promise.all(uSelectDesignOptions.map(designOption => this.getUSelectDesignOptionDetails(designOption)));
	}

	getUSelectDesignOptionDetails(uSelectDesignOption: USelectDesignOption) {
		let detailedUSelectDesignOption = new DetailedUSelectDesignOption();
		detailedUSelectDesignOption.siteId = uSelectDesignOption.siteId;
		detailedUSelectDesignOption.uSelectId = uSelectDesignOption.uSelectId;
		detailedUSelectDesignOption.uSelectName = this.getUSelectName(uSelectDesignOption.uSelectId);
		detailedUSelectDesignOption.productDesignTypeId = uSelectDesignOption.productDesignTypeId;
		return detailedUSelectDesignOption;
	}

	async getSiteUSelectBundles(siteId: number, uSelectId: number): Promise<SiteUSelectBundle[]> {
		return await lastValueFrom(this.webBundlesClient.getSiteUSelectBundles(siteId, uSelectId));
	}
	async getBundle(bundleId: number): Promise<Bundle> {
		return await lastValueFrom(this.webBundlesClient.getBundle(bundleId));
	}
	async getSiteBundles(siteId: number): Promise<SiteBundle[]> {
		return await lastValueFrom(this.webBundlesClient.getSiteBundles(siteId));
	}

	async listOrganizationsAndSites(search: OrganizationSearch): Promise<Organization[]> {
		return await lastValueFrom(this.sitesClient.listOrganizationsAndSites(search));
	}

	updateWLProductsPostage(request: UpdateSiteProductsPostageRequest) {
		return lastValueFrom(this.sitesClient.updateWLProductsPostage(request));
	}

	async getUSelectLanguages(): Promise<USelectLanguages[]> {
		return await lastValueFrom(this.sitesClient.getUSelectLanguages());
	}

	async addUSelectTranslatedName(query: USelectLanguages): Promise<USelectLanguages> {
		return await lastValueFrom(this.sitesClient.addUSelectTranslatedName(query));
	}

	async updateUSelectTranslatedName(query: USelectLanguages): Promise<USelectLanguages> {
		return await lastValueFrom(this.sitesClient.updateUSelectTranslatedName(query));
	}

	async removeUSelectLanguagesRecord(uSelectId: number, language: string): Promise<void> {
		await lastValueFrom(this.sitesClient.removeUSelectLanguagesRecord(uSelectId, language));
	}

	async getSiteWLProductLanguages(siteId: number): Promise<WLProductLanguages[]> {
		return await lastValueFrom(this.sitesClient.getSiteWLProductLanguages(siteId));
	}

	async addWLProductTranslatedName(query: WLProductLanguages): Promise<WLProductLanguages> {
		return await lastValueFrom(this.sitesClient.addWLProductTranslatedName(query));
	}

	async updateWLProductTranslatedName(query: WLProductLanguages): Promise<WLProductLanguages> {
		return await lastValueFrom(this.sitesClient.updateWLProductTranslatedName(query));
	}

	async removeWLProductLanguagesRecord(siteId: number, baseProductId: number, language: string): Promise<void> {
		await lastValueFrom(this.sitesClient.removeWLProductLanguagesRecord(siteId, baseProductId, language));
	}
}
