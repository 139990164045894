import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import {
	AddItemToCartQuery, BaseDesign, CartProduct, Customer,
	Design, DropsKind, DropsQuery, Order, PostageType,
	ProductOptionCategory, ProductOptionIdentifier, ProductPrintMethodOption,
	ShoppingCart, Site, USelectDesignType, USelectMethod, WLProduct, ShippingAndTimeRequest, PrintAndShipCategory, CustomerDistribution
} from '@taradel/web-api-client';
import { CustomerService } from 'services/customer.service';
import { SitesService } from 'services/sites.service';
import { USelect } from 'services/distributions.service';
import { SalesApiService } from 'services/sales-api.service';
import { ToastService } from 'services/toast.service';
import { ShoppingCartService } from 'services/shopping-cart.service';
import { ProductCategories, ProductCategoryOptions, ProductsService } from 'services/products.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { PrintAndShipService } from 'services/print-and-ship.service';
import { DistributionsService } from 'services/distributions.service';
import { SortPipe } from 'components/shared/sort/sort.pipe';

export interface Pricing {
	total: number;
	shippingPrice?: number;
}

@Component({
	selector: 'app-print-and-ship-customization',
	templateUrl: './print-and-ship-customization.component.html',
	styleUrls: ['./print-and-ship-customization.component.scss']
})
export class PrintAndShipCustomizationComponent implements OnInit {
	USelectDesignType = USelectDesignType;
	showSpinner = true;
	customerId = 0;
	customer!: Customer;
	customerLastOrder?: Order;
	siteId?: number;
	site!: Site;
	distribution?: CustomerDistribution;
	allUSelects: USelectMethod[] = [];
	distributionId = 0;
	siteProducts: WLProduct[] = [];
	allowedProducts: WLProduct[] = [];
	allProductOptions: ProductOptionCategory[] = [];
	printMethodOptions = new Map<number, ProductPrintMethodOption[]>();
	productOptions = new Map<number, ProductPrintMethodOption[]>();
	productDeliveryDates = new Map<number, Date>();
	cartForm: UntypedFormGroup;
	submitted = false;
	uploadedFrontFiles = new Map<number, File>();
	uploadedBackFiles = new Map<number, File>();
	shoppingCart?: ShoppingCart;
	shoppingCartProducts: AddItemToCartQuery[] = [];
	showDesign: boolean = true;
	cartProductId?: string;
	priceMatrixQuantities: number[] = [];
	productAdded = false;
	printAndShipCategories: PrintAndShipCategory[] = [];
	categorySelected = false;
	cartItemToEdit?: AddItemToCartQuery;

	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	@ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private customerService: CustomerService,
		private sitesService: SitesService,
		private toastService: ToastService,
		private salesApiService: SalesApiService,
		private shoppingCartService: ShoppingCartService,
		private productsService: ProductsService,
		private printAndShipService: PrintAndShipService,
		private distributionsService: DistributionsService,
		private sortPipe: SortPipe) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.siteId = parseInt(route.snapshot.paramMap.get('siteId') ?? '0', 10);
		this.distributionId = parseInt(route.snapshot.paramMap.get('distributionId') ?? '0', 10);
		this.cartProductId = route.snapshot.paramMap.get('cartProductId') ?? undefined;
		this.cartForm = formBuilder.group({
			products: this.formBuilder.array([])
		});
	}

	async ngOnInit(): Promise<void> {
		try {
			[this.customer, this.site, this.distribution] = await Promise.all([
				this.customerService.getCustomer(this.customerId),
				this.sitesService.getSite(this.siteId!),
				this.distributionId > 0 ? this.distributionsService.getDistribution(this.distributionId) : undefined
			]);
			this.allUSelects = await this.sitesService.getAllUSelect();
			this.allProductOptions = await this.productsService.getAllProductOptions();
			await this.loadInitialData();
		}
		catch {
			this.toastService.showError('There was a problem loading the component', 'Load Error');
		}
		finally {
			this.showSpinner = false;
		}
	}

	async loadInitialData() {
		this.siteProducts = await this.sitesService.getSiteProducts(this.siteId!);
		this.siteProducts.map(x => {
			x.baseProduct!.priceMatrix = this.sortPipe.transform(x.baseProduct?.priceMatrix ?? [], "asc", "minQty");
		});
		this.printAndShipCategories = await this.printAndShipService.getSiteCategories(this.siteId!);

		try {
			this.shoppingCart = await this.shoppingCartService.getShoppingCart(this.customerId, this.siteId!);
		}
		catch (error: any) {
			this.shoppingCart = undefined;
		}

		if (this.shoppingCart?.cartData && this.shoppingCart.cartData.length > 0 && !!this.cartProductId) {
			const selectedCartProduct = (this.shoppingCart.cartData.find(x => x.cartProductId === this.cartProductId))!;
			this.cartItemToEdit = await this.populateFields(selectedCartProduct);
		}
		else {
			this.addProduct();
		}
	}

	async selectShippingAddress(value: any, i: number) {
		const productForm = this.products.at(i) as UntypedFormGroup;
		productForm.controls.shippingAddress.setValue(value);
		const design = productForm.controls.design.value;
		if (!!design && (!!value && value > 0)) {
			await this.getShippingAndTime(i);
		}
	}

	addressFormatter = (x: { address1: string }) => x.address1;

	getProductNameWithOrderDate(wlProduct: WLProduct): string {
		const uSelectName = this.allUSelects.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId)!.name;
		const product = this.customerLastOrder?.items?.find(oi => oi.productId === wlProduct.baseProductId!);
		return product ? `(${uSelectName}) ${wlProduct.name} - (Last ordered ${this.customerLastOrder?.created.toLocaleDateString()})` : `(${uSelectName}) ${wlProduct.name}`;
	}

	get f() {
		return this.cartForm.controls;
	}

	get products() {
		return this.cartForm.controls.products as UntypedFormArray;
	}

	get options() {
		const opt = ((this.cartForm.controls.products as UntypedFormArray).get('options') as UntypedFormArray);
		return opt.controls;
	}

	getProductOptions(index: number): string[] {
		const options = (this.cartForm.controls.products as UntypedFormArray).at(index).get('options') as UntypedFormArray;
		if (options !== null) {
			return Object.keys(options.controls);
		}
		else {
			return [];
		}
	}

	addProduct() {
		const productForm = this.formBuilder.group({
			categoryId: ['', Validators.required],
			productId: ['', Validators.required],
			quantity: [''],
			design: [''],
			template: [''],
			front: [''],
			back: [''],
			deliveryWindow: [''],
			proof: [''],
			startDate: [''],
			campaignDuration: [''],
			endDate: [''],
			drops: [''],
			frequency: [''],
			shippingAddress: [''],
			jobComments: [''],
			cartProductId: ['']
		});
		this.products.push(productForm);
	}

	selectCategory(index: number) {
		let productForm = this.products.at(index) as UntypedFormGroup;

		productForm.controls.productId.setValue(null);
		const categoryId = parseInt(productForm.get('categoryId')!.value, 10);
		if (categoryId > 0) {
			this.categorySelected = true;
			this.allowedProducts = [];
			this.printAndShipCategories
				.find(x => x.categoryId === categoryId)!
				.categoryProducts?.map(p => {
					this.allowedProducts.push(this.siteProducts.find(sp => sp.baseProductId === p.productId)!);
				});
		}
		else {
			this.categorySelected = false;
		}

	}

	async populateFields(cartProduct: CartProduct): Promise<AddItemToCartQuery> {
		let cartItem: AddItemToCartQuery = new AddItemToCartQuery({
			cartProductId: cartProduct.cartProductId,
			baseProductId: cartProduct.baseProductId,
			distributionId: 0,
			budget: 0,
			quantity: cartProduct.quantity,
			jobComments: cartProduct.jobComments,
			meta: cartProduct.meta
		});

		if (cartProduct.attributes !== undefined && cartProduct.attributes.length > 0) {
			cartItem.productOptions = [];

			cartProduct.attributes.forEach(attribute => {
				const optCatId = parseInt(attribute.optCatId!);
				if (optCatId > 0) {
					cartItem.productOptions!.push(new ProductOptionIdentifier({
						optCatId,
						optionId: parseInt(attribute.value!, 10)
					}));
				}
			});
		}

		if (cartProduct.shippingAddressId) {
			cartItem.shippingAddressId = cartProduct.shippingAddressId;
		}
		if (cartProduct.drops && cartProduct.drops.orderDrops && cartProduct.drops.orderDrops.length) {
			let weeksBetweenDrops = 0;
			if (cartProduct.drops.orderDrops.length > 1) {
				const firstDrop = cartProduct.drops.orderDrops[0];
				const secondDropDate = cartProduct.drops.orderDrops[1].date!;
				const diffDays = this.getDaysBetweenDrops(firstDrop.date!, secondDropDate);
				weeksBetweenDrops = diffDays / 7;
			}
			if (cartProduct.drops.orderDrops[0].date !== undefined) {
				cartItem.drops = new DropsQuery({
					firstDropDate: cartProduct.drops.orderDrops[0].date,
					weeksBetweenDrops,
					kind: cartProduct.drops.orderDrops[0].multiple ? DropsKind.MultipleImpressions : DropsKind.SplitDrops,
					numberOfDrops: cartProduct.drops.orderDrops.length,
					endDate: cartProduct.drops.orderDrops[0].endDate ?? null as unknown as Date,
					returnAddressId: cartProduct.drops.returnAddressId
				});
			}
		}

		if (cartProduct.design !== undefined) {
			let selectedDesignOption = USelectDesignType.ProfessionalDesign;
			switch (cartProduct.design.uSelectDesign) {
				case USelectDesignType.Upload:
				case USelectDesignType.Omitted:
					// set front & back if available
					// proof required
					cartProduct.design.front!.designSelectionType = selectedDesignOption = USelectDesignType.Upload;
					break;
				case USelectDesignType.Template:
					cartProduct.design.front!.designSelectionType = selectedDesignOption = USelectDesignType.Template;
					// set template id
					break;
				case USelectDesignType.ProfessionalDesign:
					// do nothing
					cartProduct.design.front!.designSelectionType = selectedDesignOption = USelectDesignType.ProfessionalDesign;
					break;
			}
			cartItem.design = new Design({
				isProofRequired: cartProduct.design.isProofRequired,
				adText: cartProduct.design.adText,
				front: cartProduct.design.front,
				back: cartProduct.design.back,
				uSelectDesign: selectedDesignOption
			});
		}
		this.shoppingCartProducts.push(cartItem);

		const pricing: Pricing = {
			total: 0,
			shippingPrice: 0
		};
		pricing.total = cartProduct.price;
		pricing.shippingPrice = cartProduct.shippingPrice;

		await this.editCartProduct(cartItem);
		// update allowed products
		return cartItem;
	}

	async editCartProduct(addedProduct: AddItemToCartQuery) {
		this.showSpinner = true;
		this.addProduct();
		const product = addedProduct;
		let productForm = this.products.at(0) as UntypedFormGroup;
		productForm.controls.productId.setValue(product.baseProductId);
		await this.productSelected(0);
		productForm = this.products.at(0) as UntypedFormGroup;
		this.categorySelected = true;
		const category = this.printAndShipCategories.find(x => x.categoryProducts?.some(p => p.productId === product.baseProductId));
		productForm.controls.categoryId.setValue(category?.categoryId!);
		this.allowedProducts = category?.categoryProducts?.map(p => this.siteProducts.find(sp => sp.baseProductId === p.productId)!) ?? [];
		productForm.controls.cartProductId.setValue(product.cartProductId);
		productForm.controls.shippingAddress.setValue(product.shippingAddressId);
		productForm.controls.quantity.setValue(product.quantity);

		if (product.productOptions !== undefined) {
			const options = productForm.get('options') as UntypedFormGroup;
			product.productOptions.forEach(z => {
				const productOptionCategory = this.allProductOptions.find(a => a.optCatId === z.optCatId)!;
				let optionName = productOptionCategory.name!;
				options.controls[optionName].setValue(z.optionId);
			});
		}
		if (product.design !== undefined) {
			switch (product.design.uSelectDesign) {
				case USelectDesignType.Upload:
					// set front & back if available
					// proof required
					productForm.controls.design.setValue(USelectDesignType.Upload);
					break;
				case USelectDesignType.Template:
					productForm.controls.design.setValue(USelectDesignType.Template);
					// set template id
					break;
				case USelectDesignType.ProfessionalDesign:
					// do nothing
					productForm.controls.design.setValue(USelectDesignType.ProfessionalDesign);
					break;
			}
			await this.designSelected(0);
		}

		productForm.controls.jobComments.setValue(product.jobComments);
		this.showSpinner = false;
	}

	getDaysBetweenDrops(firstDropDate: Date, secondDropDate: Date): number {
		const diff = Math.abs(secondDropDate.getTime() - firstDropDate.getTime());
		return Math.ceil(diff / (1000 * 3600 * 24));
	}

	async addProductToCart(productIndex: number): Promise<AddItemToCartQuery> {
		const productForm = this.products.at(productIndex) as UntypedFormGroup;
		let query: AddItemToCartQuery = new AddItemToCartQuery();
		query = await this.buildPrintOnlyProductQuery(productIndex, productForm, true);
		return query;
	}

	buildProductOptions(productIndex: number): ProductOptionIdentifier[] {
		const productOptions: ProductOptionIdentifier[] = [];
		const selectedOptions = this.products.at(productIndex)?.get('options');
		if (selectedOptions) {
			const options = selectedOptions as UntypedFormArray;
			Object.keys(options.controls).forEach(key => {
				const optionValue = selectedOptions.get(key)?.value;
				const productOptionCategory = this.allProductOptions.find(x => x.options?.findIndex(o => o.optionId === optionValue) !== -1)!;
				productOptions.push(new ProductOptionIdentifier({
					optCatId: productOptionCategory.optCatId,
					optionId: optionValue
				}));
			});
		}
		return productOptions;
	}

	async buildDesign(productIndex: number, productForm: UntypedFormGroup): Promise<Design | undefined> {
		let design: Design | undefined;

		if (productForm.controls.design !== undefined && (productForm.controls.design.value as USelectDesignType).length > 1) {
			switch (productForm.controls.design.value) {
				case USelectDesignType.Upload:
					const frontFile = this.uploadedFrontFiles.get(productIndex) !== undefined ? this.uploadedFrontFiles.get(productIndex) : undefined;
					const backFile = this.uploadedBackFiles.get(productIndex) !== undefined ? this.uploadedBackFiles.get(productIndex) : undefined;
					const maxFileSize = 25 * 1024 * 1024; // 25MB Max file size
					let frontFileServerName = '';
					let backFileServerName = '';
					if (frontFile !== undefined && frontFile.size <= maxFileSize) {
						frontFileServerName = await this.salesApiService.uploadTemporaryFile({
							fileName: frontFile.name,
							data: frontFile
						});
					}
					if (backFile !== undefined && backFile.size <= maxFileSize) {
						backFileServerName = await this.salesApiService.uploadTemporaryFile({
							fileName: backFile.name,
							data: backFile
						});
					}
					design = new Design({
						isProofRequired: true,
						front: new BaseDesign({
							fileName: frontFile ? frontFile.name : (this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.fileName : ''),
							fileType: frontFile ? '.' + frontFile.name.split('.').pop() : (this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.fileType : ''),
							realFileName: frontFileServerName !== '' ? frontFileServerName : (this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.realFileName : ''),
							thumb: this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.thumb : '',
							designSelectionType: USelectDesignType.Upload
						}),
						back: new BaseDesign({
							fileName: backFile ? backFile.name : (this.cartItemToEdit ? this.cartItemToEdit?.design?.back?.fileName : ''),
							fileType: backFile ? '.' + backFile.name.split('.').pop() : (this.cartItemToEdit ? this.cartItemToEdit?.design?.back?.fileType : ''),
							realFileName: backFileServerName !== '' ? backFileServerName : (this.cartItemToEdit ? this.cartItemToEdit?.design?.back?.realFileName : ''),
							thumb: this.cartItemToEdit ? this.cartItemToEdit.design?.back?.thumb : '',
							designSelectionType: USelectDesignType.Upload
						}),
						uSelectDesign: USelectDesignType.Upload
					});
					break;
				case USelectDesignType.Template:
					if (productForm.controls.template.value.toString().length > 1)
						design = new Design({
							isProofRequired: false,
							front: new BaseDesign({
								artKey: productForm.controls.template.value,
								designSelectionType: USelectDesignType.Template
							}),
							uSelectDesign: USelectDesignType.Template
						});
					break;
				case USelectDesignType.ProfessionalDesign:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.ProfessionalDesign
						}),
						uSelectDesign: USelectDesignType.ProfessionalDesign
					});
					break;
				default:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.ProfessionalDesign
						}),
						uSelectDesign: USelectDesignType.ProfessionalDesign
					});
					break;
			}
		}

		return design;
	}

	async buildPrintOnlyProductQuery(productIndex: number, productForm: UntypedFormGroup, hasDesign: boolean): Promise<AddItemToCartQuery> {
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		let quantity = parseInt(productForm.controls.quantity.value);
		quantity = quantity;
		const existingCartProduct = this.shoppingCart?.cartData?.find(x => x.baseProductId === baseProductId);
		const shippingAddressId = productForm.controls.shippingAddress.value;

		const printOnlyProductQuery: AddItemToCartQuery = new AddItemToCartQuery({
			cartProductId: this.cartProductId ?? undefined,
			baseProductId,
			quantity,
			distributionId: 0,
			shippingAddressId: shippingAddressId,
			jobComments: productForm.controls.jobComments.value,
			productOptions: this.buildProductOptions(productIndex),
			design: hasDesign ? await this.buildDesign(productIndex, productForm) : undefined,
			drops: new DropsQuery({
				firstDropDate: this.productDeliveryDates.get(productIndex)!,
				kind: DropsKind.SplitDrops,
				numberOfDrops: 1,
				weeksBetweenDrops: 0,
				endDate: undefined as unknown as Date
			}),
		});

		return printOnlyProductQuery;
	}

	async saveProduct(productIndex: number) {
		this.showSpinner = true;
		let success = true;
		const cartProduct = await this.addProductToCart(productIndex);

		try {
			await this.shoppingCartService.addShoppingCart(this.customerId, this.siteId!, cartProduct);
		}
		catch (err: any) {
			success = false;
			this.toastService.showError('Cart item could not be created');
			console.log(err);
		}
		finally {
			this.showSpinner = false;
		}
		if (success) {
			if (this.distributionId > 0) {
				this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId]);
			}
			else {
				this.router.navigate(['/customers', this.customerId, 'shop', this.siteId]);
			}
		}
	}

	getPostageValue(cartProduct: AddItemToCartQuery): PostageType {
		let postageType = PostageType.StandardMail;
		if (cartProduct.productOptions) {
			const postageOption = cartProduct.productOptions.find(x => x.optCatId === ProductCategories.PostageClass);
			if (postageOption && postageOption.optionId === ProductCategoryOptions.FirstClass) {
				postageType = PostageType.FirstClass;
			}
			else if (postageOption && postageOption.optionId === ProductCategoryOptions.NonProfit) {
				postageType = PostageType.NonProfit;
			}
		}
		return postageType;
	}

	cancelCustomization() {
		if (this.distributionId > 0) {
			this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId]);
		}
		else {
			this.router.navigate(['/customers', this.customerId, 'shop', this.siteId]);
		}
	}

	async productSelected(index: number) {
		this.showSpinner = true;
		let productForm = this.products.at(index) as UntypedFormGroup;
		const categoryId = parseInt(productForm.get('categoryId')!.value, 10);
		const productId = parseInt(productForm.get('productId')!.value, 10);
		this.products.clear();
		this.addProduct();
		productForm = this.products.at(index) as UntypedFormGroup;
		productForm.controls.categoryId.setValue(categoryId);
		productForm.controls.productId.setValue(productId);
		// show product related options
		this.printMethodOptions.clear();
		this.productOptions.clear();
		this.priceMatrixQuantities = [];
		productForm.removeControl('options');
		productForm.controls.design.setValue('');
		productForm.controls.design.enable();
		const wlProduct = this.siteProducts.find(p => p.baseProductId === productId)!;
		let printMethodOptions = await this.productsService.getPrintMethodOptions(productId);
		printMethodOptions = printMethodOptions.filter(x => x.printMethodId !== undefined && x.printMethodId === (wlProduct.baseProduct?.priceMatrix![0].printMethodId ?? 1));
		this.printMethodOptions.set(productId, printMethodOptions);
		this.productOptions.set(index, printMethodOptions.filter((value, idx, self) =>
			self.findIndex(a => a.option?.optCatId === value.option?.optCatId) === idx));

		const optionsFB = this.formBuilder.group({});
		this.productOptions.get(index)!.forEach(x => {
			let optionName = this.allProductOptions.find(y => y.optCatId === x.option?.optCatId)!.name!;
			const options = this.getOptions(index, optionName);
			if (options.length === 1) {
				optionsFB.addControl(optionName, new UntypedFormControl({ value: options[0].option?.optionId, disabled: true }, Validators.required));
			}
			else {
				optionsFB.addControl(optionName, new UntypedFormControl({ value: '', disabled: false }, Validators.required));
			}
		});
		productForm.addControl('options', optionsFB);

		productForm.controls.quantity.setValue(0);
		const pricingTiers = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.priceMatrix;
		pricingTiers?.map(x => this.priceMatrixQuantities.push(x.minQty));
		productForm.get('quantity')!.setValidators([
			Validators.required,
			Validators.min(wlProduct.baseProduct?.priceMatrix![0].minQty!)
		]);
		productForm.get('quantity')!.updateValueAndValidity();
		productForm.get('shippingAddress')!.setValidators([Validators.required, Validators.min(1)]);
		productForm.get('shippingAddress')!.updateValueAndValidity();

		productForm.get('design')!.setValidators([
			Validators.required
		]);
		productForm.get('design')!.updateValueAndValidity();

		this.productAdded = true;
		this.showSpinner = false;
	}

	async optionSelected(index: number, name: string) {
		if (name === 'Postage Class') {
			const product = this.products.at(index);
			const designValue = product.get('design')!.value;
			if (designValue !== undefined) {
				await this.designSelected(index);
			}
		}
	}

	async designSelected(index: number) {
		const product = this.products.at(index);
		product.get('template')!.setValue('');
		product.get('template')!.setErrors(null);
		product.get('template')!.removeValidators(Validators.required);
		product.get('front')!.setValue('');
		product.get('back')!.setValue('');

		const shippingAddressId = product.get('shippingAddress')!.value;
		if (!!shippingAddressId && shippingAddressId > 0) {
			await this.getShippingAndTime(index);
		}
	}

	async getShippingAndTime(index: number) {
		const product = this.products.at(index);
		const productId = parseInt(product.get('productId')!.value, 10);
		const request = new ShippingAndTimeRequest({
			siteId: this.siteId!,
			baseProductId: productId,
			quantity: product.get('quantity')!.value,
			options: this.buildProductOptions(index),
			shippingAddressId: product.get('shippingAddress')!.value,
			designType: product.get('design')!.value as USelectDesignType
		});
		this.showSpinner = true;
		let response = undefined;
		try {
			response = await this.printAndShipService.getShippingAndTime(request);
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('Could not calculate shipment date');
		}
		finally {
			this.showSpinner = false;
		}
		if (!!response) {
			product.get('deliveryWindow')!.setValue(response.turnaroundTime);
			this.deliveryDateSelected(index, response.turnaroundTime!);
		}
	}

	uploadDesignSelected(index: number): boolean {
		const product = this.products.at(index) as UntypedFormGroup;
		if (!product) {
			return false;
		}
		else {
			if (product.controls.design.value === USelectDesignType.Upload) {
				return true;
			}
			else {
				return false;
			}
		}
	}

	handleFrontFileInput(i: number, event: any) {
		this.uploadedFrontFiles.set(i, event.files[0]);
	}

	handleBackFileInput(i: number, event: any) {
		this.uploadedBackFiles.set(i, event.files[0]);
	}

	deliveryDateSelected(index: number, value: string) {
		this.productDeliveryDates.set(index, new Date(value));
	}

	getProductName(product: AddItemToCartQuery): string {
		return this.siteProducts.find(p => p.baseProductId === product.baseProductId)!.name!;
	}

	getOptions(i: number, name: string): ProductPrintMethodOption[] {
		let options: ProductPrintMethodOption[] = [];
		let productOptionCategory: ProductOptionCategory | undefined;
		const productId = parseInt((this.products.at(i) as UntypedFormGroup).controls.productId.value, 10);
		const printMethodOptions = this.printMethodOptions.get(productId);
		if (this.allProductOptions.length > 0 && printMethodOptions !== undefined && printMethodOptions.length > 0) {
			productOptionCategory = this.allProductOptions.find(x => x.name === name);
			options = printMethodOptions.filter(x => x.option?.optCatId === productOptionCategory?.optCatId);
		}
		return options;
	}
}
