<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mt-3">
		<div class="col-md-3 col-12">
			<div class="card">
				<div class="card-body">
					<div class="row d-flex justify-content-between">
						<div class="col col-auto">
							<h5>Bundles</h5>
						</div>
						<div *ngIf="siteId <= 0" class="col col-auto">
							<button class="btn btn-success" [disabled]="!isSalesAdmin" (click)="saveSortOrder()">Save Sort Order</button>
						</div>
						<div *ngIf="siteId <= 0; else subscribeBtn" class="col col-auto">
							<button class="btn btn-success" [disabled]="!isSalesAdmin" (click)="addNewBundle()">Add New Bundle</button>
						</div>
						<ng-template #subscribeBtn>
							<div class="col col-auto">
								<button class="btn btn-success" [disabled]="!isSalesAdmin" (click)="openModal(subscribeSiteToBundleModal)">Subscribe To Bundle</button>
							</div>
						</ng-template>
					</div>
					<ng-container *ngIf="!!bundles">
						<div *ngFor="let bundle of bundles; index as i" class="row">
							<div *ngIf="siteId === -1" class="col-1 btn btn-link" (click)="moveBundleUp(i)"><span *ngIf="i > 0" class="fa fa-arrow-up"></span></div>
							<div *ngIf="siteId === -1" class="col-1 btn btn-link" (click)="moveBundleDown(i)"><span *ngIf="i < (bundles.length - 1)" class="fa fa-arrow-down"></span></div>
							<div class="col">
								<button class="btn btn-link" (click)="selectBundle(bundle)">{{ bundle.bundleDisplay.name }}</button>
								<button *ngIf="siteId > 0" type="button" class="btn" (click)="unsubscribeSiteFromBundle(bundle.bundleDisplay.bundleId)">
									<i class="fas fa-times-circle text-danger"></i>
								</button>
							</div>
						</div>
				</ng-container>
				</div>
			</div>
		</div>
		<ng-container *ngIf="selectedBundle && displayBundle">
			<div class="col-md-9 col-12">
				<app-bundle-details [siteId]="siteId" [bundle]="selectedBundle" [contentResponse]="selectedBundleContentResponse" [bundleCmsContent]="selectedBundleContent" [isSalesAdmin]="isSalesAdmin" (beginEditingBundle)="startBundleEditingWizard()" (deleteBundle)="deleteBundle($event)"></app-bundle-details>
			</div>
		</ng-container>
		<ng-container *ngIf="selectedBundle && customizeBundle">
			<div class="col-md-9 col-12">
				<app-bundle-customization [bundle]="selectedBundle" [contentResponse]="selectedBundleContentResponse" [selectedBundleContent]="selectedBundleContent" (cancelEdit)="cancelBundleEditingWizard()" (newBundleAdded)="handleNewBundleAdded($event)"></app-bundle-customization>
			</div>
		</ng-container>
	</div>
</app-spinner-with-overlay>

<ng-template #subscribeSiteToBundleModal let-modal>
	<div class="modal-header">
		Select Bundles To Subscribe To
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="!bundleDetailsToDisplay; else bundleDetailsTemplate">
			<div *ngFor="let bundle of bundlesNotSubscribedTo; let i = index" class="row mb-4">
				<div class="col-auto d-flex">
					<div class="form-floating align-self-center">
						<div class="form-check">
							<input type="checkbox" id="bundle-{{ i }}" class="form-check-input" name="bundle-{{ i }}" [checked]="bundle.selected" (change)="manageListToBundlesToSubscribeTo(bundle)" />
							<label for="bundle-{{ i }}" class="form-check-label">{{ bundle.bundleDisplay.name }}</label>
						</div>
					</div>
				</div>
				<div class="col d-flex">
					<div class="btn btn-link text-primary align-self-center" (click)="bundleDetailsToDisplay = bundle.bundleDisplay">View {{ bundle.bundleDisplay.name }} Bundle Details</div>
				</div>
			</div>
		</ng-container>
		<ng-template #bundleDetailsTemplate>
			<div *ngIf="bundleDetailsToDisplay" class="row mt-3">
				<div class="col">
					<div class="row">
						<div class="col-md-4 col-12">
							<h5>Bundle Details</h5>
							<div class="row mt-3">
								<div class="col">
									<strong>Name: </strong>{{ bundleDetailsToDisplay.name }}
								</div>
							</div>
							<div class="row mt-3">
								<div class="col">
									<strong>Description: </strong>{{ bundleDetailsToDisplay.description }}
								</div>
							</div>
						</div>
						<div class="col-md-8 col-12 mt-md-0 mt-3">
							<h5>Bundle Items</h5>
							<ng-container *ngIf="!!bundleDetailsToDisplay.bundleItemDisplays && bundleDetailsToDisplay.bundleItemDisplays.length > 0">
								<div *ngFor="let item of bundleDetailsToDisplay.bundleItemDisplays">
									<div class="row mb-3">
										<div class="col">
											<strong>Products:</strong>
											<ul>
												<li *ngFor="let product of item.products">{{ product.name }}</li>
											</ul>
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-6">
											<span><strong>Quantity: </strong></span>&nbsp;<span>{{ item.quantity | number }}</span>
										</div>
										<div class="col-6">
											<span><strong>Daily Spend: </strong></span>&nbsp;<span>{{ item.dailySpend | currency }}</span>
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-6">
											<span><strong>Minimum Cost: </strong></span>&nbsp;<span>{{ item.minimumCost | currency }}</span>
										</div>
										<div class="col-6">
											<div *ngIf="item.discountType === BundleDiscountType.Dollar; else percentTemplate">
												<span><strong>Discount Amount: </strong></span>&nbsp;<span>{{ item.discountAmount | currency }}</span>
											</div>
											<ng-template #percentTemplate>
												<span><strong>Discount Amount: </strong></span>&nbsp;<span>{{ item.discountAmount }}%</span>
											</ng-template>
										</div>
									</div>
									<hr />
								</div>
							</ng-container>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-auto mx-auto">
							<button class="btn btn-link text-primary" (click)="bundleDetailsToDisplay = undefined;">Go Back To List</button>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link text-danger" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-success btn-lg" [disabled]="bundlesToSubscribeTo.length <= 0" (click)="subscribeSiteToBundles()">Update</button>
	</div>
</ng-template>
