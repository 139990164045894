// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule, NgbToastModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxSliderModule } from '@angular-slider/ngx-slider';



// Main Components
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import 'ag-grid-enterprise';
import { DistributionDetailComponent } from './components/customer/distribution-detail/distribution-detail.component';
import { HomeComponent } from './components/dashboard/home/home.component';
import { CustomerMainComponent } from './components/customer/customer-main/customer-main.component';

import { SiteMainComponent } from './components/site/site-main/site-main.component';

import { UsersComponent } from './components/users/users.component';
import { LoginTestComponent } from './components/login-test/login-test.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { LoginSilentComponent } from './components/login-silent/login-silent.component';

// Component Module
//import { AgmCoreModule }                   from '@agm/core';
//import { FullCalendarModule }              from '@fullcalendar/angular';
//import dayGridPlugin                       from '@fullcalendar/daygrid';
//import timeGridPlugin                      from '@fullcalendar/timegrid';
//import interactionPlugin                   from "@fullcalendar/interaction";
//import listPlugin                          from '@fullcalendar/list';
//import bootstrapPlugin                     from '@fullcalendar/bootstrap';

//Bootstrapped Modules
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TrendModule } from 'ngx-trend';
import { TagInputModule } from 'ngx-chips';

// So far these are unused
// import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { HighlightJsModule } from 'ngx-highlight-js';
// import { CountdownModule } from 'ngx-countdown';
// import { NgChartjsModule } from 'ng-chartjs';
// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

//Functional Components
import { AuthInterceptor } from './auth-interceptor';
import { AuthenticationService } from './services/authentication.service';
import { RolesComponent } from './components/users/roles/roles.component';
import { CustomerDetailsComponent } from './components/customer/customer-details/customer-details.component';
import { CustomerDistributionsComponent } from './components/customer/customer-distributions/customer-distributions.component';
import { SiteSettingsComponent } from './components/site-settings/site-settings.component';
import { SiteDetailsComponent } from './components/site-details/site-details.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { AllProductsComponent } from './components/products/all-products/all-products.component';
import { SitesHeaderComponent } from './components/layout/sites-header/sites-header.component';
import { SiteOptionsDropdownComponent } from './components/site-options-dropdown/site-options-dropdown.component';
import { CustomerOrdersComponent } from './components/customer/orders/customer-orders.component';
import { AddressDetailsComponent } from 'components/customer/addresses/address-details/address-details.component';
import { MainComponent } from './components/layout/main.component';
import { OrderSummaryComponent } from './components/orders/order-summary/order-summary.component';
import { OrderMainComponent } from './components/orders/order-main/order-main.component';
import { OrderItemDetailsComponent } from './components/orders/order-item-details/order-item-details.component';
import { ProductDetailsComponent } from 'components/products/product-details/product-details.component';
import { ProductOptionsComponent } from './components/products/product-options/product-options.component';
import { ProductPricingComponent } from './components/products/product-pricing/product-pricing.component';
import { SiteDashboardComponent } from './components/site-dashboard/site-dashboard.component';
import { ProductInfoComponent } from './components/products/product-info/product-info.component';
import { SiteProductsComponent } from './components/site/site-products/site-products.component';
import { WLProductDetailsComponent } from './components/site/site-products/wl-product-details/wl-product-details.component';
import { AddWlProductComponent } from './components/site/site-products/add-wl-product/add-wl-product.component';
import { ProductLinksComponent } from './components/products/product-links/product-links.component';
import { CustomerLinksComponent } from './components/customer/customer-links/customer-links.component';
import { CustomerInfoComponent } from './components/customer/customer-info/customer-info.component';
import { PrintersComponent } from './components/printers/printers.component';
import { PrinterComponent } from './components/printers/printer/printer.component';
import { PrinterLinksComponent } from 'components/printers/printer-links/printer-links/printer-links.component';
import { UserComponent } from './components/users/user/user.component';
import { DashboardComponent } from './components/orders/dashboard/dashboard.component';
import { CouponsComponent } from './components/site/coupons/coupons.component';
import { CouponDetailsComponent } from './components/site/coupons/coupon-details/coupon-details.component';
import { ConfirmModalComponent } from './components/shared/confirm-modal/confirm-modal.component';
import { ConfigComponent } from './components/config/all-config-counts.component';
import { ConfigForSitesComponent } from './components/config-for-sites/config-for-sites.component';
import { LogsComponent } from './components/logs/logs.component';
import { MapsComponent } from './components/maps/maps.component';

import { HeaderComponent } from './components/layout/header/header.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { ThemeSettingsService } from 'services/theme-settings.service';
import { CustomerHeaderComponent } from './components/customer/customer-header/customer-header.component';
import { PagefullheaderComponent } from './components/layout/pagefullheader/pagefullheader.component';
import { OrderLayoutComponent } from './components/orders/order-layout/order-layout.component';
import { OrderPaymentsComponent } from './components/orders/order-payments/order-payments.component';
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
// 	suppressScrollX: true
// };
import { MapPasteListComponent } from './components/customer/map-paste-list/map-paste-list.component';
import { MapUploadListComponent } from './components/customer/map-upload-list/map-upload-list.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { SpinnerWithOverlayComponent } from './components/shared/spinner/spinner-with-overlay/spinner-with-overlay.component';
import { ChartSparkLineComponent } from './components/shared/chart-spark-line/chart-spark-line.component';
import { ProductOptionCategoriesComponent } from './components/products/product-option-categories/product-option-categories.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AlertBoxComponent } from './components/shared/alert-box/alert-box.component';
import { PlaceholderComponent } from './components/shared/placeholder/placeholder.component';
import { PurchaseOrderComponent } from './components/orders/order-item-details/purchase-order/purchase-order.component';
import { OrderItemDeliveryComponent } from './components/orders/order-item-details/order-item-delivery/order-item-delivery.component';
import { EddmDeliveryDropsComponent } from './components/orders/order-item-details/order-item-delivery/eddm-delivery-drops/eddm-delivery-drops.component';
import { EddmDeliveryImpressionsComponent } from './components/orders/order-item-details/order-item-delivery/eddm-delivery-impressions/eddm-delivery-impressions.component';
import { VendorRecommendationComponent } from './components/orders/order-item-details/purchase-order/vendor-recommendation/vendor-recommendation.component';
import { ChangeDistributionComponent } from './components/orders/order-item-details/order-item-delivery/change-distribution/change-distribution.component';
import { PrinterZonesComponent } from './components/printers/printer-zones/printer-zones.component';
import { PrinterInfoComponent } from './components/printers/printer-info/printer-info.component';
import { EligiblePrintersComponent } from './components/orders/order-item-details/purchase-order/eligible-printers/eligible-printers.component';
import { EddmStatusTableComponent } from './components/reports/eddm-status-table/eddm-status-table.component';
import { ContentKeysComponent } from './components/site/content-keys/content-keys.component';
import { SiteContentComponent } from 'components/site/site-content/site-content.component';
import { UrlsComponent } from 'components/site/urls/urls.component';
import {
	EmailInputModule, OptionListModule, PhoneInputModule, AddressSelectionModule, SelectModule,
	ListSelectionModule, SelectTextareaComboModule, SliderModule, SliderSelectionModule, TextareaModule,
	ToggleModule, ToggleSelectionModule, UploadedListFieldMappingModule, ValueSelectionModule, OrderDashboardModule, StateListModule,
} from '@taradel/white-label-common';
import { SiteUselectAddonsComponent } from './components/site/site-uselect-addons/site-uselect-addons.component';
import { McnallyReportComponent } from './components/reports/mcnally-report/mcnally-report.component';
import { SalesIndicatorsReportComponent } from './components/reports/sales-indicators-report/sales-indicators-report.component';
import { EddmStatusReportComponent } from './components/reports/eddm-status-report/eddm-status-report.component';
import { AddSiteUselectComponent } from './components/site/site-uselect-addons/add-site-uselect/add-site-uselect.component';
import { SiteUselectDetailsComponent } from './components/site/site-uselect-addons/site-uselect-details/site-uselect-details.component';
import { UselectSearchComponent } from './components/site/site-uselect-addons/uselect-search/uselect-search.component';
import { SharedModule } from '@taradel/white-label-common';
import { PersonalizedMailComponent } from './components/personalized-mail/personalized-mail.component';
import { AddressComponent } from './components/shared/address/address.component';
import { ZipcodesAddressComponent } from './components/personalized-mail/zipcodes-address/zipcodes-address.component';
import { SelectionSummaryComponent } from './components/personalized-mail/selection-summary/selection-summary.component';
import { ListPreviewComponent } from './components/shared/list-preview/list-preview.component';
import { EquifaxB2bComponent } from './components/equifax-b2b/equifax-b2b.component';
import { B2bSelectionSummaryComponent } from './components/equifax-b2b/b2b-selection-summary/b2b-selection-summary.component';
import { BusinessComponent } from './components/equifax-b2b/business/business.component';
import { FinanceComponent } from './components/equifax-b2b/finance/finance.component';
import { GeographyComponent } from './components/equifax-b2b/geography/geography.component';
import { IndustryComponent } from './components/equifax-b2b/industry/industry.component';
import { UploadYourListComponent } from './components/upload-your-list/upload-your-list.component';
import { ContentKeyForSitesComponent } from './components/site/content-key-for-sites/content-key-for-sites.component';
import { DigitalDeliveryComponent } from './components/orders/order-item-details/order-item-delivery/digital-delivery/digital-delivery.component';
import { SitesListComponent } from './components/shared/sites-list/sites-list.component';
import { CreateCustomerComponent } from './components/customer/create-customer/create-customer.component';
import { ToastsComponent } from './components/shared/toasts/toasts.component';
import { SiteUselectDesignsComponent } from './components/site/site-uselect-designs/site-uselect-designs.component';
import { AddUselectDesignComponent } from './components/site/site-uselect-designs/add-uselect-design/add-uselect-design.component';
import { SiteUselectDesignDetailsComponent } from './components/site/site-uselect-designs/site-uselect-design-details/site-uselect-design-details.component';
import { CustomListSearchComponent } from './components/orders/order-main/custom-list-search/custom-list-search.component';
import { PMODComponent } from './components/printers/pmod/pmod.component';
import { PrettyPrintJsonPipe } from './components/shared/pretty-print-json/pretty-print-json.pipe';
import { UselectsComponent } from './components/products/uselects/uselects.component';
import { UselectDetailsComponent } from './components/products/uselects/uselect-details/uselect-details.component';
import { AddUselectComponent } from 'components/products/uselects/add-uselect/add-uselect.component';
import { SitePowerUpsComponent } from './components/site/site-power-ups/site-power-ups.component';
import { CreateShoppingCartComponent } from './components/customer/shopping-carts/create-shopping-cart/create-shopping-cart.component';
import { ShoppingQuotesComponent } from './components/customer/shopping-quotes/shopping-quotes.component';
import { QuoteCheckoutComponent } from './components/customer/shopping-quotes/quote-checkout/quote-checkout.component';
import { CustomerAddressesComponent } from './components/shared/customer-addresses/customer-addresses.component';
import { NewAddressComponent } from './components/shared/new-address/new-address.component';
import { DigitalDeliveryDropsComponent } from './components/orders/order-item-details/order-item-delivery/digital-delivery-drops/digital-delivery-drops.component';
import { AddressedMailDeliveryDropsComponent } from './components/orders/order-item-details/order-item-delivery/addressed-mail-delivery-drops/addressed-mail-delivery-drops.component';
import { CustomerDesignsComponent } from './components/customer/customer-designs/customer-designs.component';
import { ShoppingCartsComponent } from './components/customer/shopping-carts/shopping-carts.component';
import { CategoriesComponent } from './components/print-and-ship/categories/categories.component';
import { CategoryProductsComponent } from './components/print-and-ship/category-products/category-products.component';
import { AddCategoryComponent } from './components/print-and-ship/add-category/add-category.component';
import { CreateSiteComponent } from './components/site/create-site/create-site.component';
import { CustomersComponent } from './components/shared/customers/customers.component';
import { DirectMailCustomizationComponent } from 'components/customer/shopping-carts/create-shopping-cart/direct-mail-customization/direct-mail-customization.component';
import { PrintAndShipCustomizationComponent } from './components/customer/shopping-carts/create-shopping-cart/print-and-ship-customization/print-and-ship-customization.component';
import { BundlesComponent } from './components/customer/shopping-carts/create-shopping-cart/bundles/bundles.component';
import { BundleComponent } from './components/customer/shopping-carts/create-shopping-cart/bundles/bundle/bundle.component';
import { OptionCategoriesComponent } from './components/products/option-categories/option-categories.component';
import { AddOptionCategoryComponent } from './components/products/option-categories/add-option-category/add-option-category.component';
import { OptionCategoryDetailsComponent } from './components/products/option-categories/option-category-details/option-category-details.component';
import { CategoryOptionsComponent } from './components/products/option-categories/category-options/category-options.component';
import { AddCategoryOptionComponent } from './components/products/option-categories/category-options/add-category-option/add-category-option.component';
import { CategoryOptionDetailsComponent } from './components/products/option-categories/category-options/category-option-details/category-option-details.component';
import { CustomerAddressListComponent } from './components/customer/addresses/customer-address-list/customer-address-list.component';
import { InfoPanelComponent } from './components/customer/shopping-carts/create-shopping-cart/info-panel/info-panel.component';
import { ProductPowerupsComponent } from './components/products/product-powerups/product-powerups.component';
import { DesignTurnaroundComponent } from './components/products/uselects/design-turnaround/design-turnaround.component';
import { InductionTurnaroundComponent } from './components/products/uselects/induction-turnaround/induction-turnaround.component';
import { DesignTypeComponent } from './components/products/uselects/design-type/design-type.component';
import { OrganizationsComponent } from './components/organizations/organizations.component';
import { DebugUserLoginComponent } from './components/users/debug-user-login/debug-user-login.component';
import { BulkAddProductsComponent } from 'components/shared/bulk-add-products/bulk-add-products.component';
import { ProductPcogsComponent } from './components/products/product-pcogs/product-pcogs.component';
import { SuppressListComponent } from './components/customer/distribution-detail/suppress-list/suppress-list.component';
import { PcogOptionsComponent } from './components/products/product-pcogs/pcog-options/pcog-options.component';
import { PcogOptionComponent } from './components/products/product-pcogs/pcog-options/pcog-option/pcog-option.component';
import { SnapDeliveryDropsComponent } from './components/orders/order-item-details/order-item-delivery/snap-delivery-drops/snap-delivery-drops.component';
import { SnapDeliveryImpressionsComponent } from './components/orders/order-item-details/order-item-delivery/snap-delivery-impressions/snap-delivery-impressions.component';
import { PcogOptionsDropdownComponent } from './components/products/product-pcogs/pcog-options/pcog-options-dropdown/pcog-options-dropdown.component';
import { DeliveryComponent } from './components/customer/orders/delivery/delivery.component';
import { ExtraCopiesCustomizationComponent } from './components/customer/shopping-carts/create-shopping-cart/direct-mail-customization/extra-copies-customization/extra-copies-customization.component';
import { PcogsManagementComponent } from './components/pcogs-management/pcogs-management.component';
import { ProductsListComponent } from 'components/pcogs-management/products-list/products-list.component';
import { PrintersListComponent } from 'components/pcogs-management/printers-list/printers-list.component';
import { UpdatePostageComponent } from './components/site/update-postage/update-postage.component';
import { UpdateUselectPostageComponent } from './components/products/uselects/update-uselect-postage/update-uselect-postage.component';
import { CaMapsComponent } from './components/maps/ca-maps/ca-maps/ca-maps.component';
import { CaPasteRoutesComponent } from './components/maps/ca-maps/ca-paste-routes/ca-paste-routes.component';
import { WLProductLanguagesComponent } from './components/site/site-products/wlproduct-languages/wlproduct-languages.component';
import { USelectsLanguagesComponent } from './components/products/uselects/uselects-languages/uselects-languages.component';
import { PnsDeliveryDropsComponent } from './components/orders/order-item-details/order-item-delivery/pns-delivery-drops/pns-delivery-drops.component';
import { CustomerPaymentsComponent } from './components/payments/customer-payments/customer-payments.component';
import { PaymentDetailsComponent } from './components/payments/customer-payments/payment-details/payment-details.component';
import { RecurringPaymentsComponent } from './components/customer/payments/recurring-payments/recurring-payments.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { PaymentAttemptsComponent } from './components/payments/payment-attempts/payment-attempts.component';
import { CustomerPaymentAttemptsComponent } from './components/payments/customer-payment-attempts/customer-payment-attempts.component';
import { CreatePaymentComponent } from './components/payments/customer-payments/create-payment/create-payment.component';
import { PaymentsSectionComponent } from './components/customer/payments/payments-section/payments-section.component';
import { CustomerInvoicesComponent } from './components/payments/customer-invoices/customer-invoices.component';
import { ChangeOrderComponent } from './components/orders/change-order/change-order.component';
import { ChangeOrderItemComponent } from './components/orders/change-order/change-order-item/change-order-item.component';
import { PaymentProfilesComponent } from './components/customer/payments/payment-profiles/payment-profiles.component';
import { CreateRecurringPaymentComponent } from './components/customer/payments/recurring-payments/create-recurring-payment/create-recurring-payment.component';
import { RefundPaymentComponent } from './components/payments/customer-payments/refund-payment/refund-payment.component';
import { ScheduledPaymentsComponent } from './components/payments/scheduled-payments/scheduled-payments.component';
import { CreatePaymentProfileComponent } from './components/customer/payments/payment-profiles/create-payment-profile/create-payment-profile.component';
import { UpdateOrderItemComponent } from './components/orders/change-order/update-order-item/update-order-item.component';
import { PaymentAttemptDetailsComponent } from './components/payments/customer-payment-attempts/payment-attempt-details/payment-attempt-details.component';
import { UpdatePnsOrderItemComponent } from './components/orders/change-order/update-pns-order-item/update-pns-order-item.component';
import { AttributionReportComponent } from './components/reports/attribution-report/attribution-report.component';
import { UpdateOrderCouponComponent } from './components/orders/change-order/update-order-coupon/update-order-coupon.component';
import { OrderBalanceReportComponent } from 'components/reports/order-balance-report/order-balance-report.component';
import { SortPipe } from 'components/shared/sort/sort.pipe';
import { BundleDetailsComponent } from './components/all-bundles/bundles-display/bundle-details/bundle-details.component';
import { BundleCustomizationComponent } from './components/all-bundles/bundles-display/bundle-customization/bundle-customization.component';
import { BundleDetailsCustomizationComponent } from './components/all-bundles/bundles-display/bundle-customization/bundle-details-customization/bundle-details-customization.component';
import { BundleItemCustomizationComponent } from './components/all-bundles/bundles-display/bundle-customization/bundle-item-customization/bundle-item-customization.component';
import { BundleItemsComponent } from './components/all-bundles/bundles-display/bundle-customization/bundle-items/bundle-items.component';
import { BundlesDisplayComponent } from 'components/all-bundles/bundles-display/bundles-display.component';
import { AllBundlesComponent } from './components/all-bundles/all-bundles.component';
import { UpdateOrderBundleComponent } from './components/orders/change-order/update-order-bundle/update-order-bundle.component';
import { BulkAddProductsToSiteComponent } from './components/site/site-products/add-wl-product/bulk-add-products-to-site/bulk-add-products-to-site.component';
import { BundleContentCustomizationComponent } from './components/all-bundles/bundles-display/bundle-customization/bundle-content-customization/bundle-content-customization.component';
import { CustomizeBundleComponent } from './components/customer/shopping-carts/create-shopping-cart/customize-bundle/customize-bundle.component';
import { BundleContentComponent } from './components/all-bundles/bundles-display/bundle-customization/bundle-content/bundle-content.component';

@NgModule({
	declarations: [
		AppComponent,
		DistributionDetailComponent,
		HomeComponent,
		CustomerMainComponent,
		SiteMainComponent,
		UsersComponent,
		LoginTestComponent,
		LoginCallbackComponent,
		LoginSilentComponent,
		TemplatesComponent,
		AllProductsComponent,
		ProductDetailsComponent,
		CustomerDetailsComponent,
		CustomerDistributionsComponent,
		SiteSettingsComponent,
		SiteDetailsComponent,
		MainComponent,
		SitesHeaderComponent,
		SiteOptionsDropdownComponent,
		CustomerOrdersComponent,
		AddressDetailsComponent,
		MainComponent,
		RolesComponent,
		UsersComponent,
		OrderMainComponent,
		OrderSummaryComponent,
		OrderItemDetailsComponent,
		ProductOptionsComponent,
		ProductPricingComponent,
		SiteDashboardComponent,
		ProductInfoComponent,
		SiteProductsComponent,
		WLProductDetailsComponent,
		AddWlProductComponent,
		ProductLinksComponent,
		CustomerLinksComponent,
		CustomerInfoComponent,
		UserComponent,
		PrintersComponent,
		PrinterComponent,
		PrinterLinksComponent,
		DashboardComponent,
		LogsComponent,
		MapsComponent,
		CouponsComponent,
		CouponDetailsComponent,
		ConfirmModalComponent,
		ConfigComponent,
		ConfigForSitesComponent,
		LogsComponent,
		HeaderComponent,
		SidebarComponent,
		PrinterLinksComponent,
		PrinterComponent,
		CustomerHeaderComponent,
		PagefullheaderComponent,
		OrderLayoutComponent,
		OrderPaymentsComponent,
		MapPasteListComponent,
		MapUploadListComponent,
		SpinnerComponent,
		SpinnerWithOverlayComponent,
		ChartSparkLineComponent,
		ProductOptionCategoriesComponent,
		AlertBoxComponent,
		PlaceholderComponent,
		PurchaseOrderComponent,
		OrderItemDeliveryComponent,
		EddmDeliveryDropsComponent,
		EddmDeliveryImpressionsComponent,
		VendorRecommendationComponent,
		ChangeDistributionComponent,
		PrinterZonesComponent,
		PrinterInfoComponent,
		EligiblePrintersComponent,
		EddmStatusTableComponent,
		SiteContentComponent,
		ContentKeysComponent,
		UrlsComponent,
		SiteUselectAddonsComponent,
		McnallyReportComponent,
		SalesIndicatorsReportComponent,
		EddmStatusReportComponent,
		PersonalizedMailComponent,
		AddressComponent,
		ZipcodesAddressComponent,
		SelectionSummaryComponent,
		ListPreviewComponent,
		EquifaxB2bComponent,
		B2bSelectionSummaryComponent,
		BusinessComponent,
		FinanceComponent,
		GeographyComponent,
		IndustryComponent,
		ContentKeyForSitesComponent,
		AddSiteUselectComponent,
		SiteUselectDetailsComponent,
		UselectSearchComponent,
		UploadYourListComponent,
		DigitalDeliveryComponent,
		CreateCustomerComponent,
		SitesListComponent,
		ToastsComponent,
		SiteUselectDesignsComponent,
		AddUselectDesignComponent,
		SiteUselectDesignDetailsComponent,
		CustomListSearchComponent,
		PMODComponent,
		PrettyPrintJsonPipe,
		SortPipe,
		UselectsComponent,
		AddUselectComponent,
		UselectDetailsComponent,
		SitePowerUpsComponent,
		CreateShoppingCartComponent,
		ShoppingQuotesComponent,
		QuoteCheckoutComponent,
		CustomerAddressesComponent,
        NewAddressComponent,
        DigitalDeliveryDropsComponent,
        AddressedMailDeliveryDropsComponent,
        CustomerDesignsComponent,
        ShoppingCartsComponent,
        CategoriesComponent,
        CategoryProductsComponent,
        AddCategoryComponent,
        CreateSiteComponent,
        CustomersComponent,
        DirectMailCustomizationComponent,
        PrintAndShipCustomizationComponent,
        BundlesComponent,
        BundleComponent,
        OptionCategoriesComponent,
        AddOptionCategoryComponent,
        OptionCategoryDetailsComponent,
        CategoryOptionsComponent,
        AddCategoryOptionComponent,
        CategoryOptionDetailsComponent,
        CustomerAddressListComponent,
        InfoPanelComponent,
        ProductPowerupsComponent,
        DesignTurnaroundComponent,
        InductionTurnaroundComponent,
        DesignTypeComponent,
        OrganizationsComponent,
        DebugUserLoginComponent,
        BulkAddProductsComponent,
        SuppressListComponent,
        UpdatePostageComponent,
        UpdateUselectPostageComponent,
        SnapDeliveryDropsComponent,
        SnapDeliveryImpressionsComponent,
        ProductPcogsComponent,
        SuppressListComponent,
        PcogOptionsComponent,
        PcogOptionComponent,
        SnapDeliveryDropsComponent,
        SnapDeliveryImpressionsComponent,
        PcogOptionsDropdownComponent,
        DeliveryComponent,
        ExtraCopiesCustomizationComponent,
        PcogOptionsDropdownComponent,
        CaMapsComponent,
        CaPasteRoutesComponent,
        PcogsManagementComponent,
        ProductsListComponent,
        PrintersListComponent,
        WLProductLanguagesComponent,
        USelectsLanguagesComponent,
        PnsDeliveryDropsComponent,
        CustomerPaymentsComponent,
        PaymentDetailsComponent,
        RecurringPaymentsComponent,
        PaymentsComponent,
        PaymentAttemptsComponent,
        CustomerPaymentAttemptsComponent,
        CreatePaymentComponent,
        PaymentsSectionComponent,
        CustomerInvoicesComponent,
        PaymentProfilesComponent,
        ChangeOrderComponent,
        ChangeOrderItemComponent,
        CreateRecurringPaymentComponent,
        RefundPaymentComponent,
        ScheduledPaymentsComponent,
        CreatePaymentProfileComponent,
        UpdateOrderItemComponent,
        PaymentAttemptDetailsComponent,
        UpdatePnsOrderItemComponent,
        AttributionReportComponent,
        OrderBalanceReportComponent,
        UpdateOrderCouponComponent,
        BundleDetailsComponent,
        BundleCustomizationComponent,
        BundleDetailsCustomizationComponent,
        BundleItemCustomizationComponent,
        BundleItemsComponent,
		BundlesDisplayComponent,
  		AllBundlesComponent,
        UpdateOrderBundleComponent,
        BulkAddProductsToSiteComponent,
        BundleContentCustomizationComponent,
        CustomizeBundleComponent,
        BundleContentComponent
	],
	imports: [
		AgGridAngular,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		GoogleMapsModule,
		GooglePlaceModule,
		NgxSliderModule,
		HttpClientModule,
		LoadingBarRouterModule,
		NgbModule,
		NgbToastModule,
		TagInputModule,
		TrendModule,
		NgApexchartsModule,
		EmailInputModule,
		OptionListModule,
		ListSelectionModule,
		PhoneInputModule,
		AddressSelectionModule,
		SharedModule,
		SelectModule,
		SelectTextareaComboModule,
		SliderModule,
		SliderSelectionModule,
		TextareaModule,
		ToggleModule,
		ToggleSelectionModule,
		UploadedListFieldMappingModule,
		ValueSelectionModule,
		OrderDashboardModule,
		StateListModule,
		NgbAccordionModule
	],
	providers: [
		Title,
		AuthenticationService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeAppCustomLogic,
			multi: true,
			deps: [AuthenticationService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		ThemeSettingsService,
		SortPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
		router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				const title = 'Taradel Admin | '; // + this.route.snapshot.firstChild.data['title'];
				this.titleService.setTitle(title);
			}
		});
	}
}

export function initializeAppCustomLogic(authService: AuthenticationService): () => Promise<void> {
	const nonAuthPaths = ['/login-silent', '/login-callback'];
	return async () => {
		if (!nonAuthPaths.includes(window.location.pathname))
			await authService.init();
	};
}
