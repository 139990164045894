import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Bundle, BundleDisplay, BundleItem, BundleItemDisplay, BundleContent, BundleContentResponse, BundleCmsContent, CmsContent, UpdateBundleRequest, CmsContentKey } from '@taradel/admin-api-client';
import { BundlesService } from 'services/bundles.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-bundle-customization',
  templateUrl: './bundle-customization.component.html',
  styleUrls: ['./bundle-customization.component.scss']
})
export class BundleCustomizationComponent implements OnInit, OnChanges {
	siteId?: number;
	bundleItemToEdit?: BundleItemDisplay;
	bundleItemToEditIndex?: number;
	showBundle = false;
	showBundleItems = false;
	showBundleItem = false;
	showBundleContent = false;
	reviewBundle = false;
	selectedBundle?: BundleDisplay;
	loading = false;

	@Input() bundle?: BundleDisplay;
	@Input() contentResponse?: BundleContentResponse;
	@Input() selectedBundleContent: CmsContent[] = [];
	@Output() cancelEdit = new EventEmitter();
	@Output() newBundleAdded = new EventEmitter<number>();

	constructor(
		private bundlesService: BundlesService,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		this.loading = true;
		this.goToEditBundle();
		this.loading = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!!this.bundle && Object.keys(this.bundle).length <= 0) {
			this.showBundle = true;
			this.showBundleItems = false;
			this.showBundleItem = false;
			this.showBundleContent = false;
			this.reviewBundle = false;
		}
		this.selectedBundle = this.bundle;
	}

	goToEditBundle() {
		this.showBundle = true;
		this.showBundleItems = false;
		this.showBundleItem = false;
		this.showBundleContent = false;
		this.reviewBundle = false;
	}

	handleUpdatingBundle(value: Bundle) {
		if (!this.bundle) {
			this.bundle = new BundleDisplay();
		}
		this.bundle.name = value.name!;
		this.bundle.description = value.description!;
		this.bundle.deleted = value.deleted;
		this.goToBundleItems();
	}

	goBackToBundleDetails() {
		this.cancelEdit.emit();
	}

	goToBundleItems() {
		this.showBundle = false;
		this.showBundleItems = true;
	}

	goBackToListOfBundleItems() {
		this.showBundleItem = false;
		this.showBundleContent = false;
		this.showBundleItems = true;
	}

	goToEditBundleItem(response: { item: BundleItemDisplay, index: number }) {
		this.bundleItemToEdit = response.item;
		this.bundleItemToEditIndex = response.index;
		this.showBundleItems = false;
		this.showBundleItem = true;
	}

	goBackToEditBundle() {
		this.showBundleItems = false;
		this.showBundle = true;
	}

	handleUpdatingBundleItem(response: { index: number, updatedItem: BundleItemDisplay }) {
		// we should definitely have a bundle, at this point
		if (!!this.bundle) {
			if (response.updatedItem.sortOrder <= 0) {
				response.updatedItem.sortOrder = this.bundle.bundleItemDisplays ? this.bundle.bundleItemDisplays.length + 1 : 0;
			}
			if (!!this.bundle.bundleItemDisplays) {
				const item = this.bundle.bundleItemDisplays[response.index];
				if (!!item) {
					this.bundle.bundleItemDisplays.splice(response.index, 1, response.updatedItem);
				}
				else {
					this.bundle.bundleItemDisplays.push(response.updatedItem);
				}
			}
			else {
				const bundleItemDisplays: BundleItemDisplay[] = [];
				bundleItemDisplays.push(response.updatedItem);
				this.bundle.bundleItemDisplays = bundleItemDisplays;
			}
			this.bundle.bundleItemDisplays.sort((a, b) => a.sortOrder - b.sortOrder);
		}
		this.bundleItemToEdit = undefined;
		this.showBundleItems = true;
		this.showBundleItem = false;
	}

	removeBundleItem(item: BundleItemDisplay) {
		if (!!this.bundle && !!this.bundle.bundleItemDisplays) {
			const index = this.bundle.bundleItemDisplays?.findIndex(b => b === item);
			if (index > -1) {
				this.bundle.bundleItemDisplays?.splice(index, 1);
			}
		}
	}

	handleUpdatingBundleContent(content: BundleContent) {
		if (!!this.bundle) {
			this.bundle.bundleContent = content;
			this.goToReviewBundle();
		}
	}

	handleUpdatingBundleCmsContent(content: CmsContent[]) {
		if (!!this.bundle) {
			this.selectedBundleContent = content;
		}
	}

	goToBundleContent() {
		this.showBundleItems = false;
		this.showBundleContent = true;
	}

	goToReviewBundle() {
		this.showBundleContent = false;
		this.reviewBundle = true;
	}

	async saveBundle(bundle: BundleDisplay) {
		if (!this.bundle) {
			return;
		}

		let newBundleItems: BundleItem[] = [];
		bundle.bundleItemDisplays!.forEach(item => {
			const newItem = new BundleItem();
			newItem.bundleItemId = 0;
			newItem.bundleId = 0;
			newItem.quantity = item.quantity;
			newItem.dailySpend = item.dailySpend;
			newItem.minimumCost = item.minimumCost;
			newItem.discountType = item.discountType;
			newItem.discountAmount = item.discountAmount;
			newItem.sortOrder = item.sortOrder;
			newItem.productConfiguration = item.productConfiguration;
			newItem.required = item.required;
			newBundleItems.push(newItem);
		});

		const updatedBundleContent = new BundleContent();
		updatedBundleContent.bundleColor = this.bundle.bundleContent?.bundleColor;
		updatedBundleContent.bundleId = this.bundle.bundleId ?? 0;
		updatedBundleContent.descriptionContentKey = this.bundle.bundleContent?.descriptionContentKey;
		updatedBundleContent.detailsContentKey = this.bundle.bundleContent?.detailsContentKey;
		updatedBundleContent.nameContentKey = this.bundle.bundleContent?.nameContentKey;

		const newBundle =  new Bundle();
		newBundle.bundleId = this.bundle.bundleId ?? 0;
		newBundle.name = this.bundle.name;
		newBundle.description = this.bundle.description;
		newBundle.deleted = this.bundle.deleted;
		newBundle.bundleItems = newBundleItems;
		newBundle.bundleContent = updatedBundleContent;

		let newContent: CmsContent[] = [];
		this.selectedBundleContent?.forEach(c => {
			const newContentItem = new CmsContent();
			newContentItem.content = c.content;
			newContentItem.contentKey = new CmsContentKey();
			newContentItem.contentKey.contentKey = c.contentKey?.contentKey;
			newContentItem.contentKey.contentType = c.contentKey?.contentType!;
			newContentItem.language = c.language;
			newContentItem.siteId = c.siteId;
			newContent.push(newContentItem);
		});

		const updateBundleRequest = new UpdateBundleRequest();
		updateBundleRequest.bundle = newBundle;
		if (newContent.length) {
			updateBundleRequest.content = newContent;
		}

		this.loading = true;
		let newBundleId = 0;
		try {
			if (newBundle.bundleId > 0) {
				newBundleId = await this.bundlesService.updateBundle(updateBundleRequest);
			}
			else {
				newBundleId = await this.bundlesService.createBundle(updateBundleRequest);
			}
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('Bundle could not be updated');
		}
		finally {
			this.loading = false;
		}
		if (newBundleId > 0) {
			this.toastService.showSuccess('Bundle updated/added');
			this.newBundleAdded.emit(newBundleId);
		}
	}
}
