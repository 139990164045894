import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PersonalizedMailClient as WebPersonalizedMailClient, CountsDetail, MatchedAddress, PersonalizedUploadListResponse, PMCertifyUploadedListRequest,
	PMIdentifyListRequest, PMIdentifyListResponse,
	UploadedListCountsDetail } from '@taradel/web-api-client';
import { CustomerDistribution, FileParameter, PersonalizedMailClient, PersonalizedMailQuery, PMSaveListQuery, PMSaveUploadedListQuery, SuppressListQuery } from '@taradel/admin-api-client';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PmDataService {

	private pmClient: PersonalizedMailClient;
	private webPmClient: WebPersonalizedMailClient;

	constructor(http: HttpClient) {
		this.pmClient = new PersonalizedMailClient(http, environment.adminApiUrl);
		this.webPmClient = new WebPersonalizedMailClient(http, environment.webApiUrl);
	}

	getCount(query: PersonalizedMailQuery): Promise<CountsDetail> {
		return lastValueFrom(this.webPmClient.countsViaService(query));
	}

	getAddressPreview(query: PersonalizedMailQuery): Promise<MatchedAddress[]> {
		return lastValueFrom(this.webPmClient.getAddressPreview(query));
	}

	saveBuiltList(customerId: number, query: PMSaveListQuery) {
		return lastValueFrom(this.pmClient.saveBuiltList(customerId, query));
	}

	storeUploadedList(uploadedFile: FileParameter): Promise<PersonalizedUploadListResponse> {
		return lastValueFrom(this.webPmClient.storeUploadedList(uploadedFile));
	}

	identifyUploadedListColumns(query: PMIdentifyListRequest): Promise<PMIdentifyListResponse> {
		return lastValueFrom(this.webPmClient.identifyUploadedListColumns(query));
	}

	certifyUploadedList(query: PMCertifyUploadedListRequest): Promise<UploadedListCountsDetail> {
		return lastValueFrom(this.webPmClient.certifyUploadedList(query));
	}

	saveUploadedList(customerId: number, query: PMSaveUploadedListQuery): Promise<number> {
		return lastValueFrom(this.pmClient.saveUploadedList(customerId, query));
	}

	getCustomerDistributionsForSuppression(customerId: number, distributionId: number): Promise<CustomerDistribution[]> {
		return lastValueFrom(this.pmClient.getCustomerListOfDistributionsForSuppression(customerId, distributionId));
	}

	processListSuppression(query: SuppressListQuery): Promise<boolean> {
		return lastValueFrom(this.pmClient.suppressDistribution(query));
	}
}
