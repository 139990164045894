import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SiteUSelectBundle, WLProduct, ProductPricingResponse } from '@taradel/web-api-client';

@Component({
	selector: 'app-bundles',
	templateUrl: './bundles.component.html',
	styleUrls: ['./bundles.component.scss']
})
export class BundlesComponent implements OnChanges {
	modalRef: any;
	productPricing: ProductPricingResponse[] = [];
	disabled = false;

	@Input() bundles: SiteUSelectBundle[] = [];
	@Input() siteProducts: WLProduct[] = [];
	@Input() siteId!: number;
	@Input() customerId!: number;
	@Input() selectedBundle?: SiteUSelectBundle;

	@Output() bundleSelected = new EventEmitter<SiteUSelectBundle>();
	@Output() bundleRemoved = new EventEmitter<SiteUSelectBundle>();

	constructor() { }

	updateSelectedBundle() {
		if (!!this.selectedBundle) {
			this.disabled = true;
		}
		else this.disabled = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.selectedBundle || (changes.selectedBundle.currentValue === changes.selectedBundle.previousValue)) {
			return;
		}
		this.updateSelectedBundle();
	}

	handleBundleSelected(bundle: SiteUSelectBundle) {
		this.disabled = true;
		this.bundleSelected.emit(bundle);
	}

	handleSelectedBundleRemoved() {
		this.bundleRemoved.emit(this.selectedBundle);
	}
}
