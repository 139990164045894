<ng-container *ngIf="customerId">
	<app-pagefullheader></app-pagefullheader>
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="row">
			<div class="col-md-8">
				<div class="card p-3">
					<ng-container *ngIf="showInit">
						<div class="card">
							<div class="card-header">
								<strong>Select One Of the Following Options</strong>
							</div>
							<div class="card-body">
								<div class="form-check">
									<input type="checkbox" id="withOrders" class="form-check-input" type="radio" (change)="createPaymentWithOrders = true;" [ngModel]="createPaymentWithOrders === true" [disabled]="!remainingOrderBalances">
									<label for="withOrders" class="form-check-label">Associate payment with existing orders</label>
								</div>
								<div class="form-check">
									<input type="checkbox" id="withoutOrders" class="form-check-input" type="radio" (change)="createPaymentWithOrders = false;" [ngModel]="createPaymentWithOrders === false">
									<label for="withoutOrders" class="form-check-label">Create payment without associating orders</label>
								</div>
								<div class="row mt-3 d-flex justify-content-center">
									<div class="col col-auto">
										<button type="button" class="btn btn-primary btn-sm" (click)="initializePaymentAmount()">Continue</button>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="setPaymentAmount">
						<div *ngIf="beginWithOrders; else justSetPaymentAmount">
							<div class="card">
								<div class="card-header">
									<strong>Select Order(s) To Include In Payment and Set Associated Payment Amount(s)</strong>
								</div>
								<div class="card-body">
									<ng-container *ngIf="!loading && remainingOrderBalances && (orderPayments && orderPayments.length > 0)">
										<div class="form-check form-switch" *ngIf="orderId === 0">
											<input class="form-check-input" type="checkbox" role="switch" id="checkAllBoxes" [ngModel]="selectAllOrders" (ngModelChange)="handleSelectAllOrders()" />
											<label class="form-check-label" for="checkAllBoxes"><strong>Select All Orders</strong></label>
										</div>
										<table class="table table-hover table-panel">
											<thead>
												<tr>
													<th>Include Order In Payment</th>
													<th>Order Id</th>
													<th>Site</th>
													<th>Created Date</th>
													<th>Order Amount</th>
													<th class="text-danger">Order Balance</th>
													<th>Payment Amount</th>
												</tr>
											</thead>
											<tbody [formGroup]="paymentForm">
													<ng-container formArrayName="orderPayments">
														<ng-container *ngFor="let orderPayment of orderPayments; let i = index">
																<tr [formGroup]="orderPayment">
																	<td>
																		<div class="form-check">
																			<input type="checkbox" id="'includeOrder' + balance.orderId" class="form-check-input" formControlName="selected" [checked]="orderPayment.get('selected')?.value" (change)="disablePaymentAmountInput(i)" />
																		</div>
																	</td>
																	<td>
																		<a [routerLink]="['/orders/', orderPayment.get('order')?.value.orderId, 'summary']" target="_blank" class="btn btn-link p-0">{{ orderPayment.get('order')?.value.orderId }}</a>
																	</td>
																	<td>
																		{{ orderPayment.get('order')?.value.affiliateId }}
																	</td>
																	<td>
																		{{ orderPayment.get('order')?.value.created | date:'short' }}
																	</td>
																	<td>
																		{{ orderPayment.get('order')?.value.orderAmt | currency }}
																	</td>
																	<td class="text-danger">
																		{{ orderPayment.get('order')?.value.orderBalance | currency }}
																	</td>
																	<td>
																		<div class="form-floating">
																			<input type="number" class="form-control" formControlName="paymentAmount" placeholder="orderPayment.get('paymentAmount')?.value" [readonly]="orderPayment.get('selected')?.value === false" />
																			<label>Set amount for payment</label>
																		</div>
																	</td>
																</tr>
														</ng-container>
													</ng-container>
											</tbody>
											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td>
														<strong>Total: {{ getTotalPaymentAmount() | currency }}</strong>
													</td>
												</tr>
											</tfoot>
										</table>
										<div class="row mt-3 d-flex justify-content-center">
											<div class="col col-auto" *ngIf="orderId === 0">
												<button type="button" class="btn btn-link" (click)="showInit = true; setPaymentAmount = false; beginWithOrders = undefined; control.paymentTotal.reset();">
													Go Back
												</button>
											</div>
											<div class="col col-auto">
												<button type="button" class="btn btn-small btn-primary" (click)="setPaymentAmount = false; setBillingAddress = true;" [disabled]="control.paymentTotal.value <= 0">
													Select Billing Address
												</button>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
						<ng-template #justSetPaymentAmount>
							<div class="card">
								<div class="card-header">
									<strong>Set Payment Amount</strong>
								</div>
								<div class="card-body">
									<form [formGroup]="paymentForm">
										<div class="form-floating">
											<input type="number" class="form-control" formControlName="paymentTotal" placeholder="control.paymentTotal.value" />
											<label>Set amount for payment</label>
										</div>
									</form>
									<div class="row mt-3 d-flex justify-content-center">
										<div class="col col-auto">
											<button type="button" class="btn btn-link" (click)="showInit = true; setPaymentAmount = false; beginWithOrders = undefined; control.paymentTotal.reset();">
												Go Back
											</button>
										</div>
										<div class="col col-auto">
											<button type="button" class="btn btn-small btn-primary" (click)="setPaymentAmount = false; setBillingAddress = true;" [disabled]="control.paymentTotal.value <= 0">
												Select Billing Address
											</button>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="setBillingAddress">
						<div class="card">
							<div class="card-header">
								<strong>Select Billing Address</strong>
							</div>
							<div class="card-body">
								<strong>Payment Total: {{ control.paymentTotal?.value | currency }}</strong>
								<div class="row mt-3">
									<div class="col-md-6 col">
										<app-customer-addresses [submitted]="false" [addressType]="'Billing'"
											[customerId]="customerId"
											[cartAddressId]="control.billingAddressId.value"
											(addressSelected)="selectBillingAddress($event)">
										</app-customer-addresses>
									</div>
								</div>
								<div class="row d-flex justify-content-center">
									<div class="col col-auto">
										<button type="button" class="btn btn-link" (click)="setPaymentAmount = true; setBillingAddress = false;">
											Go Back
										</button>
									</div>
									<div class="col col-auto">
										<button type="button" class="btn btn-small btn-primary" (click)="goToCreatePayment()" [disabled]="control.billingAddressId.value <= 0">
											Select Payment Type
										</button>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="setPaymentType">
						<div class="card">
							<div class="card-header">
								<strong>Select Payment Type</strong>
							</div>
							<div class="card-body">
								<strong>Payment Total: {{ control.paymentTotal?.value | currency }}</strong>
								<div class="row mt-3">
									<div class="col-md-6 col">
										<div *ngIf="customerPaymentProfiles.length" class="form-check">
											<input class="form-check-input"
												[checked]="control.paymentType.value === PaymentType.CreditCard && usePaymentProfile" type="radio"
												id="payWithProfile" (click)='setPaymentTypeToProfile()' />
											<label for="payWithProfile" class="form-check-label">
												Pay with customer profile
											</label>
										</div>
										<div class="form-check mt-2">
											<input class="form-check-input"
												[checked]="control.paymentType.value === PaymentType.CreditCard && !usePaymentProfile" type="radio"
												id="payWithCC" (click)='selectPaymentType(PaymentType.CreditCard)' />
											<label for="payWithCC" class="form-check-label">
												Pay with credit card
											</label>
										</div>
										<ng-container *ngIf="isSalesAdmin">
											<div class="form-check mt-2">
												<input class="form-check-input"
													[checked]="control.paymentType.value === PaymentType.Check" type="radio"
													id="payWithCheck" (click)='selectPaymentType(PaymentType.Check)' />
												<label for="payWithCheck" class="form-check-label">
													Pay with check
												</label>
											</div>
											<div class="form-check mt-2">
												<input class="form-check-input"
													[checked]="control.paymentType.value === PaymentType.Wire" type="radio"
													id="payWithWire" (click)='selectPaymentType(PaymentType.Wire)' />
												<label for="payWithWire" class="form-check-label">
													Pay with wire
												</label>
											</div>
										</ng-container>
									</div>
									<div *ngIf="isSalesAdmin" class="col-md-6 col">
										<div class="form-check mt-2">
											<input class="form-check-input"
												[checked]="control.paymentType.value === PaymentType.CreditKeyPostAuth" type="radio"
												id="payWithCreditKey" (click)='selectPaymentType(PaymentType.CreditKeyPostAuth)' />
											<label for="payWithCreditKey" class="form-check-label">
												Pay with Credit Key
											</label>
										</div>
										<div class="form-check mt-2">
											<input class="form-check-input"
												[checked]="control.paymentType.value === PaymentType.Cash" type="radio"
												id="payWithCash" (click)='selectPaymentType(PaymentType.Cash)' />
											<label for="payWithCash" class="form-check-label">
												Pay with cash
											</label>
										</div>
										<div class="form-check mt-2">
											<input class="form-check-input"
												[checked]="control.paymentType.value === PaymentType.MoneyOrder" type="radio"
												id="payWithMoneyOrder" (click)='selectPaymentType(PaymentType.MoneyOrder)' />
											<label for="payWithMoneyOrder" class="form-check-label">
												Pay with money order
											</label>
										</div>
										<div class="form-check mt-2">
											<input class="form-check-input"
												[checked]="control.paymentType.value === PaymentType.ACH" type="radio"
												id="payWithAch" (click)='selectPaymentType(PaymentType.ACH)' />
											<label for="payWithAch" class="form-check-label">
												Pay with ACH
											</label>
										</div>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-8 col mx-auto" [ngSwitch]="control.paymentType.value">
										<div *ngSwitchCase="PaymentType.CreditCard">
											<ng-container *ngIf="usePaymentProfile">
												<form [formGroup]="customerProfileForm">
													<div class="form-floating">
														<select type="select" formControlName="customerPaymentProfileId" class="form-select">
															<option disabled>Select payment profile</option>
															<option *ngFor="let x of customerPaymentProfiles" value="{{ x.customerPaymentProfileId }}">
																{{(x.creditCardMaskedData?.cardType ?? 'Checking')}} - {{(x.creditCardMaskedData?.maskedCard ?? x.achMaskedData?.accountNumber) }}
															</option>
														</select>
														<div *ngIf="profileControl.customerPaymentProfileId.errors && profileControl.customerPaymentProfileId.touched"
															class="input-error">
															<div *ngIf="profileControl.customerPaymentProfileId.errors.required">
																Customer Payment Profile Id is required
															</div>
														</div>
													</div>
												</form>
											</ng-container>
											<ng-container *ngIf="!usePaymentProfile">
												<form [formGroup]="creditCardForm" id="creditCardForm">
													<div class="form-group">
														<div class="col-12 mb-2">
															<div class="row">
																<div class="col-md-9">
																	<div class="input-group">
																		<input [type]="showCCNum ? 'text' : 'password'" id="creditCardNumber" formControlName='creditCardNumber'
																			id="creditCardNumber" class="form-control mt-2" minlength="13"
																			maxlength="16" placeholder="Credit Card Number"
																			data-validate="required" />
																		<span class="input-group-text mt-2" (click)="toggleShowCCNum()">
																			<i [ngClass]="showCCNum ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
																		</span>
																	</div>
																	<div *ngIf="ccControl.creditCardNumber.errors"
																		class="input-error">
																		<div
																			*ngIf="ccControl.creditCardNumber.errors.required && ccControl.creditCardNumber.touched">
																			Credit Card is required</div>
																		<div
																			*ngIf="ccControl.creditCardNumber.errors.minlength">
																			Minimum Credit Card length is 13</div>
																	</div>

																</div>
																<div class="col-md-3">
																	<div class="input-group">
																		<input [type]="showCVV ? 'text' : 'password'"  formControlName='cvv' id="cvv"
																			class="form-control mt-2" placeholder="CVV" minlength="3"
																			maxlength="4" data-validate="required" />
																			<span class="input-group-text mt-2" (click)="toggleShowCVV()">
																				<i [ngClass]="showCVV ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
																			</span>
																	</div>
																	<div *ngIf="ccControl.cvv.errors && ccControl.cvv.touched"
																		class="input-error">
																		<div *ngIf="ccControl.cvv.errors.required">
																			CVV is required</div>
																		<div *ngIf="ccControl.cvv.errors.minlength">
																			Minimum length is 3</div>
																	</div>
																</div>
															</div>
															<div class="row mt-2">
																<div class="col-6">
																	<select formControlName='expirationMonth' id="expirationMonth"
																		class="form-select"
																		(change)="adjustYear($any($event.target).value)">
																		<option value="" selected disabled>Expiration
																			Month
																			<span class="caret"></span>
																		</option>
																		<option value="01">January</option>
																		<option value="02">February</option>
																		<option value="03">March</option>
																		<option value="04">April</option>
																		<option value="05">May</option>
																		<option value="06">June</option>
																		<option value="07">July</option>
																		<option value="08">August</option>
																		<option value="09">September</option>
																		<option value="10">October</option>
																		<option value="11">November</option>
																		<option value="12">December</option>
																	</select>
																	<div *ngIf="ccControl.expirationMonth.errors && ccControl.expirationMonth.touched"
																		class="input-error">
																		<div *ngIf="ccControl.expirationMonth.errors.required">
																			Expiration Month is required</div>
																	</div>
																</div>
																<div class="col-6">
																	<select formControlName='expirationYear' id="expirationYear"
																		class="form-select">
																		<option value="" selected disabled>Expiration
																			Year <span class="caret"></span></option>
																		<option *ngFor="let year of creditCardYear" value="{{year}}">
																			{{year}}</option>
																	</select>
																	<div *ngIf="ccControl.expirationYear.errors && ccControl.expirationYear.touched"
																		class="input-error">
																		<div *ngIf="ccControl.expirationYear.errors.required">
																			Expiration Year is required</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</form>
											</ng-container>
										</div>
										<div *ngSwitchCase="PaymentType.ACH">
											<form [formGroup]="achForm">
												<div class="form-floating mb-2">
													<input type="text" class="form-control" formControlName="routingNumber" pattern="\d{9}"
													placeholder="Enter Routing Number" [minlength]="9" [maxlength]="9"/>
													<label>Routing Number</label>
													<div *ngIf="achControl.routingNumber.errors && achControl.routingNumber.touched"
														class="input-error">
														<div *ngIf="achControl.routingNumber.errors.required">
															Routing number is required
														</div>
														<div *ngIf="achControl.routingNumber.errors.pattern">
															Routing number is 9 digits
														</div>
													</div>
												</div>
												<div class="form-floating mb-2">
													<input type="text" class="form-control" formControlName="accountNumber" [minlength]="8" [maxlength]="12"
													placeholder="Enter Account Number" pattern="\d{10}|\d{12}"/>
													<label>Account Number</label>
													<div *ngIf="achControl.accountNumber.errors && achControl.accountNumber.touched"
														class="input-error">
														<div *ngIf="achControl.accountNumber.errors.required">
															Account number is required
														</div>
														<div *ngIf="achControl.accountNumber.errors.pattern">
															Account number is 8 to 12 digits
														</div>
													</div>
												</div>
												<div class="form-floating mb-2">
													<input type="text" class="form-control" formControlName="nameOnAccount" placeholder="Enter Name on Account" />
													<label>Enter Name on Account</label>
													<div *ngIf="achControl.nameOnAccount.errors && achControl.nameOnAccount.touched"
														class="input-error">
														<div *ngIf="achControl.nameOnAccount.errors.required">
															Name is required
														</div>
													</div>
												</div>
												<div class="form-floating mb-2">
													<input type="text" class="form-control" formControlName="bankName" placeholder="Enter Bank Name" />
													<label>Bank Name</label>
													<div *ngIf="achControl.bankName.errors && achControl.bankName.touched"
														class="input-error">
														<div *ngIf="achControl.bankName.errors.required">
															Bank Name is required
														</div>
													</div>
												</div>
											</form>
										</div>
										<div *ngSwitchDefault>
											<form [formGroup]="paymentReferenceForm">
												<div class="form-floating" *ngIf="control.paymentType.value !== PaymentType.Cash">
													<input type="text" class="form-control" formControlName="paymentRef" placeholder="Enter {{ paymentRefPlaceholder }}" />
													<label>{{ paymentRefPlaceholder }}</label>
													<div *ngIf="paymentRefControl.paymentRef.errors && paymentRefControl.paymentRef.touched"
														class="input-error">
														<div *ngIf="paymentRefControl.paymentRef.errors.required">
															{{ paymentRefPlaceholder }} is required
														</div>
													</div>
												</div>
											</form>
										</div>
										<div *ngIf="control.paymentType.value" class="row mt-3">
											<form [formGroup]="paymentForm">
												<div class="form-floating">
													<input type="text" class="form-control" formControlName="note" maxlength="255" placeholder="Include a note" />
													<label>Include a note</label>
												</div>
												<div *ngIf="control.note.errors" class="input-error">
													Note should not exceed 255 characters
												</div>
											</form>
										</div>
									</div>
								</div>
								<div class="row mt-3 d-flex justify-content-center">
									<div class="col col-auto">
										<button type="button" class="btn btn-link" (click)="setBillingAddress = true; setPaymentType = false;">
											Go Back
										</button>
									</div>
									<div *ngIf="!!customerId && customerId > 0" class="col col-auto">
										<button type="button" class="btn btn-small btn-primary" (click)="createPayment()">
											Create Payment
										</button>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<div class="row mt-3 d-flex">
						<div class="col col-auto">
							<a [routerLink]="['/customers/', customerId, 'payments']" type="button">Go back to customer payments</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</app-spinner-with-overlay>
</ng-container>
