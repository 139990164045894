<button class="btn btn-success" (click)="showBundleModal(bundleModal)" [disabled]="disabled">
	Add {{ bundle.name }} Bundle
</button>

<ng-template #bundleModal let-modal>
	<app-confirm-modal title="{{ bundle.name }} Bundle Details" confirmText="add to cart" (confirmAction)="selectBundle()" (closeModal)="closeModal()">
		<section id="bundleDescription">
			<section id="bundleDetails">
				<p class="details-header">
					Up to {{ getTotalImpressions(bundle.bundleProducts!) }} ad views
				</p>
				<p class="details-header">
					Free ad design
				</p>
				<p class="details-header">
					Reporting dashboard
				</p>
			</section>
			<section id="bundleProducts">
				<div *ngFor="let prod of bundle.bundleProducts">
					<p class="details-header">{{ getProductName(prod.productId) }}</p>
					<ul>
						<li>
							Est. {{ getImpressionRange(prod) }} ad views
						</li>
						<li *ngIf="prod.productId === 260">
							Desktop and mobile ads
						</li>
						<li *ngIf="prod.productId === 272">
							Ads run directly in feed
						</li>
					</ul>
				</div>
			</section>
		</section>
		<section id="bundlePricing">
			<p *ngIf="bundle?.coupon?.discountPercent; else percentIsFalse" class="details-header">
				Price: +{{ getTotal(bundle) | currency : currencyCode : currencySymbol }} (Save {{ bundle.coupon?.discountAmount }}%)
			</p>
			<ng-template #percentIsFalse>
				<p>
					Price: +{{ getTotal(bundle) | currency : currencyCode : currencySymbol }} (Save {{ bundle.coupon?.discountAmount | currency : currencyCode : currencySymbol : '1.0-0' }})
				</p>
			</ng-template>
		</section>
	</app-confirm-modal>
</ng-template>
