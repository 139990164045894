import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BundleDisplay, BundleDiscountType, BundleItemDisplay } from '@taradel/admin-api-client';

@Component({
  selector: 'app-bundle-items',
  templateUrl: './bundle-items.component.html',
  styleUrls: ['./bundle-items.component.scss']
})
export class BundleItemsComponent {
	BundleDiscountType = BundleDiscountType;

	@Input() bundle?: BundleDisplay;

	@Output() itemToEdit = new EventEmitter<{ item: BundleItemDisplay, index: number }>();
	@Output() itemToRemove = new EventEmitter<BundleItemDisplay>();
	@Output() goBack = new EventEmitter();
	@Output() goToBundleContent = new EventEmitter();

	emitItemToEdit(item: BundleItemDisplay, index: number) {
		this.itemToEdit.emit({ item, index });
	}

	emitItemToRemove(item: BundleItemDisplay) {
		this.itemToRemove.emit(item);
	}

	emitGoBack() {
		this.goBack.emit();
	}

	emitGoToBundleContent() {
		this.goToBundleContent.emit();
	}

	addNewItem() {
		const newBundleItem = new BundleItemDisplay();
		const index = this.bundle?.bundleItemDisplays ? this.bundle?.bundleItemDisplays.length : 0;
		this.emitItemToEdit(newBundleItem, index);
	}
}
