import { Injectable } from '@angular/core';
import { CustomerDistribution, DistributionsClient, EddmDistribution, FileResponse, GetCustomerDistributionsQuery, GetNumberOfDropsQuery, MigrateDistributionQuery, PersonalizedMailClient, SnapAdMailClient, SnapAdMailDistribution } from '@taradel/admin-api-client';
import { EddmClient, CombineListQuery } from '@taradel/admin-api-client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

export enum USelect {
	eddm = 1,
	snapAdmail = 2,
	targetedEmail = 4,
	uploadYourList = 5,
	buildYourList = 6,
	purchasedList = 10,
	displayAds = 12,
	facebookAds = 13,
	informedDelivery = 14,
	printAndShip = 15,
	buildPoliticalList = 16,
	uploadPoliticalList = 19,
	b2B = 21,
	callTracking = 23,
	equifaxB2B = 24,
	linkedinAds = 25,
	businessProfile = 27,
	extraCopies = 28,
	instagram = 29,
	spotify= 30,
	hulu = 31,
	qrCode = 32,
	tiktok = 33,
	customFee = 34,
	nextdoorAds = 35
}

export namespace USelect {
	export function IsDirectMail(uselect: USelect): boolean {
		return uselect === USelect.eddm ||
			uselect === USelect.snapAdmail ||
			uselect === USelect.buildYourList ||
			uselect === USelect.uploadYourList ||
			uselect === USelect.buildPoliticalList ||
			uselect === USelect.uploadPoliticalList ||
			uselect === USelect.b2B ||
			uselect === USelect.equifaxB2B;
	}

	export function IsPrint(uselect: USelect): boolean {
		return uselect === USelect.eddm ||
		uselect === USelect.snapAdmail ||
		uselect === USelect.buildYourList ||
		uselect === USelect.uploadYourList ||
		uselect === USelect.printAndShip ||
		uselect === USelect.buildPoliticalList ||
		uselect === USelect.uploadPoliticalList ||
		uselect === USelect.b2B ||
		uselect === USelect.equifaxB2B ||
		uselect === USelect.extraCopies;
	}

	export function  IsAddressedMail(uselect: USelect): boolean {
		return uselect === USelect.buildYourList ||
		uselect === USelect.uploadYourList ||
		uselect === USelect.buildPoliticalList ||
		uselect === USelect.uploadPoliticalList ||
		uselect === USelect.b2B ||
		uselect === USelect.equifaxB2B;
	}

	export function IsDigital(uselect: USelect): boolean {
		return uselect === USelect.targetedEmail ||
		uselect === USelect.displayAds ||
		uselect === USelect.facebookAds ||
		uselect === USelect.linkedinAds ||
		uselect === USelect.instagram ||
		uselect === USelect.spotify ||
		uselect === USelect.hulu ||
		uselect === USelect.qrCode ||
		uselect === USelect.tiktok ||
		uselect === USelect.nextdoorAds ||
		uselect === USelect.businessProfile;
	}
    export function IsPartOfBundle(uselect: USelect): boolean {
		return uselect === USelect.targetedEmail ||
        uselect === USelect.displayAds ||
		uselect === USelect.facebookAds ||
		uselect === USelect.linkedinAds ||
		uselect === USelect.instagram ||
		uselect === USelect.spotify ||
		uselect === USelect.hulu ||
		uselect === USelect.tiktok ||
		uselect === USelect.nextdoorAds;
	}

	export function IsTracking(uselect: USelect): boolean {
		return uselect === USelect.callTracking ||
		uselect === USelect.informedDelivery;
	}

	export function IsPurchasedData(uselect: USelect): boolean {
		return uselect === USelect.purchasedList;
	}

	export function IsDataSource(uselect: USelect): boolean {
		return uselect === USelect.eddm ||
		uselect === USelect.snapAdmail ||
		uselect === USelect.buildYourList ||
		uselect === USelect.buildPoliticalList ||
		uselect === USelect.b2B ||
		uselect === USelect.equifaxB2B;
	}
}

@Injectable({
	providedIn: 'root'
})
export class DistributionsService {

	private distributionsClient: DistributionsClient;
	private eddmClient: EddmClient;
	private snapAdmailClient: SnapAdMailClient;
	private personalizedMailClient: PersonalizedMailClient;

	constructor(http: HttpClient) {
		this.distributionsClient = new DistributionsClient(http, environment.adminApiUrl);
		this.eddmClient = new EddmClient(http, environment.adminApiUrl);
		this.snapAdmailClient = new SnapAdMailClient(http, environment.adminApiUrl);
		this.personalizedMailClient = new PersonalizedMailClient(http, environment.adminApiUrl);
	}

	getEddmDistribution(customerId: number, distributionId: number): Promise<EddmDistribution> {
		return lastValueFrom(this.eddmClient.getCustomerEddmDistribution(customerId, distributionId));
	}
	getSnapAdmailDistribution(customerId: number, distributionId: number): Promise<SnapAdMailDistribution> {
		return lastValueFrom(this.snapAdmailClient.getCustomerSnapAdMailDistribution(customerId, distributionId));
	}

	getCustomerDistributionsCount(query: GetCustomerDistributionsQuery): Promise<number> {
		return lastValueFrom(this.distributionsClient.customerDistributionsCount(query));
	}

	getAllDistributions(query: GetCustomerDistributionsQuery): Promise<CustomerDistribution[]> {
		return lastValueFrom(this.distributionsClient.getCustomerDistributions(query));
	}

	getDistribution(distributionId: number): Promise<CustomerDistribution> {
		return lastValueFrom(this.distributionsClient.getDistribution(distributionId));
	}

	async deleteDistribution(customerId: number, distributionId: number) {
		await lastValueFrom(this.distributionsClient.deleteDistribution(customerId, distributionId));
	}

	async deleteDistributions(customerId: number, distributionIds: number[]) {
		await lastValueFrom(this.distributionsClient.deleteDistributions(customerId, distributionIds));
	}

	async restoreDistribution(customerId: number, distributionId: number) {
		await lastValueFrom(this.distributionsClient.restoreDistribution(customerId, distributionId));
	}

	getMinimumAllowedNumberOfDrops(customerId: number, query: GetNumberOfDropsQuery): Promise<number> {
		return lastValueFrom(this.distributionsClient.getMinimumAllowedNumberOfDrops(customerId, query));
	}

	listDistFiles(distributionId: number): Promise<string[]> {
		return lastValueFrom(this.distributionsClient.listDistributionFiles(distributionId));
	}

	async getDistFile(distributionId: number, fileKey: string): Promise<FileResponse | null> {
		return await lastValueFrom(this.distributionsClient.getDistributionFile(distributionId, fileKey));
	}

	async transferDistributionToCustomer(query: MigrateDistributionQuery) {
		await lastValueFrom(this.distributionsClient.migrateDistributionCopy(query));
	}

	async downloadList(distributionId: number): Promise<void> {
		const fileResponse = await lastValueFrom(this.distributionsClient.downloadBuiltList(distributionId));
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(fileResponse?.data!);
		let date = new Date;
		const filename = `${distributionId}-listdownload-${date.toLocaleDateString()}`;
		link.download = filename + '.csv';
		link.click();
	}

	async combineLists(query: CombineListQuery): Promise<boolean> {
		return await lastValueFrom(this.personalizedMailClient.combineLists(query));
	}
}
